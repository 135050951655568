import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import CollectjsForm from "../components/checkout/CollectjsForm";
const firebaseConfig = {
  apiKey: "AIzaSyC1Eysh93VWOD17C-TV-SXljorkNlkdt4U",
  authDomain: "parkupfrontdev.firebaseapp.com",
  projectId: "parkupfrontdev",
  storageBucket: "parkupfrontdev.appspot.com",
  messagingSenderId: "598847264915",
  appId: "1:598847264915:web:239f2effb572f01c9dd566",
  measurementId: "G-HHN8Z8R2WS",
};

const app = initializeApp(firebaseConfig);
const FirebaseHoc = (props) => {
  async function sendToFirestore() {
    const id = uuidv4();
    let checkoutFormData = props.checkoutForm;
    const db = getFirestore();
    if (props.checkoutForm.Image) {
      const storage = getStorage();
      const fileExtension = props.checkoutForm.Image.name.split(".").pop();
      const storageRef = ref(storage, "legends/" + id + "." + fileExtension);
      const uploadTask = await uploadBytes(
        storageRef,
        props.checkoutForm.Image
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);

      checkoutFormData = Object.assign({}, props.checkoutForm, {
        Image: downloadURL,
      });
    }
    let res = await setDoc(doc(db, "legends", id), checkoutFormData);
    console.log({ res });
    return res;
  }
  return <CollectjsForm {...props} onCheckout={sendToFirestore} />;
};

export default FirebaseHoc;
