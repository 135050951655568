import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentSuccessful from "./PaymentSuccessful";
import PaymentProcessing from "./PaymentProcessing";
import PaymentAlternativeMethod from "./PaymentAlternativeMethod";
import PaymentFailed from "./PaymentFailed";
import { useLocation } from "react-router-dom";

const PaymentStatus = () => {
  const [message, setMessage] = useState(null);
  const location = useLocation();
  useEffect(() => {
    setMessage(PaymentSuccessful());
  }, []);

  return message;
};

export default PaymentStatus;
