import React, { useState, useRef } from "react";

const UploadAndDisplayImage = ({ onChange, value }) => {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setImage = onChange ? onChange : () => {};
  const selectedImage = value;
  return (
    <div
      style={{
        padding: 10,
        flex: 1,
        width: "100%",
        display: "flex",
      }}
    >
      <div
        className="FormElement"
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-start",
          color: "grey",
        }}
      >
        {"VEHICLE IMAGE: "}
        <div
          style={{ display: "flex", flex: 1, justifyContent: "center" }}
          onClick={handleClick}
        >
          {selectedImage ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <img
                alt="not found"
                width={"250px"}
                height={"250px"}
                src={URL.createObjectURL(selectedImage)}
              />
            </div>
          ) : (
            <div>{"UPLOAD"}</div>
          )}
        </div>
      </div>
      <input
        ref={hiddenFileInput}
        type="file"
        accept="image/*"
        className="FormElement"
        onChange={(event) => {
          console.log("event.target.files[0]", event.target.files[0]);
          setImage({ target: { name: "Image", value: event.target.files[0] } });
        }}
        hidden
      />
    </div>
  );
};

export default UploadAndDisplayImage;
