import React from "react";

function Liability() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div
        className="card"
        style={{
          display: "flex",
          flex: 0.9,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 25,
          backgroundColor: "white",
          opacity: 1,
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          <header style={{ textAlign: "center" }}>
            <h1>LIABILITY WAIVER</h1>
          </header>
          <section>
            <p>
              Read all of the following Releases and Waivers of Liability and
              Assumption of Risk Agreements carefully before agreeing to
              &quot;REGISTER&quot; on the Legends Rally Mobile App.
            </p>
            <p>&#8203;</p>
            <p>
              BY AGREEING TO &quot;REGISTER&quot; ON THE Legends Rally MOBILE
              APP YOU ARE ACCEPTING THE FOLLOWING WAIVER OF LIABILITY WRITTEN
              BELOW FOR ALL Legends Rally OFFICIAL EVENTS AS WELL AS ALL OTHER
              EVENTS POSTED ON THE Legends Rally MOBILE APP BY ANY THIRD PARTY
              CAR CLUBS OR EVENT ORGANIZERS:
            </p>
            <p>&#8203;</p>
            <p>
              IN CONSIDERATION of being allowed to participate in any way in any
              Official Legends Rally event or Unofficial Event (&ldquo;3rd Party
              Event&rdquo;):
            </p>
            <p>&#8203;</p>
            <p>
              1. I understand and agree that, although I have been supplied with
              certain information on the prescribed Legends Rally Event or 3rd
              Party Event course, the nature of th Legends Rally Event or 3rd
              Party Event does not permit me to inspect the prescribed course or
              any portion of the prescribed course or the immediately adjacent
              areas thereof with which I may come in contact during the Legends
              Rally Event prior to my participation in the Legends Rally Event
              or 3rd Party Event. I understand and agree that, if at any time, I
              feel anything to be UNSAFE, I will immediately take all necessary
              precautions to avoid the unsafe area and REFUSE TO PARTICIPATE
              further in the Legends Rally Event or 3rd Party Event.
              &quot;Official Legends Rally event&quot; (&quot;Legends Rally
              Event&quot;) does not constitute an event organized by Maclevin
              Ventures LLC nor does it indicate having any legal representation
              or assume any liability with said event. The label &quot;Official
              Event&quot; is used for marketing purposes only.
            </p>
            <p>&#8203;</p>
            <p>2. I understand that:</p>
            <p>
              a. Participation in the Legends Rally Event or 3rd Party Event
              involves RISKS AND DANGERS, as does any roadway vehicular
              activity, of serious BODILY INJURY, including PERMANENT
              DISABILITY, PARALYSIS, AND DEATH (&ldquo;RISKS&rdquo;);
            </p>
            <p>
              b. The SOCIAL AND ECONOMIC LOSSES and/or damages which could
              result from those risks COULD BE SEVERE;
            </p>
            <p>
              c. These risks and dangers may be caused by my own actions, or
              inactions, the actions or inactions of others, the rules of the
              Legends Rally Event or 3rd Party Event, the condition and layout
              of the prescribed course, or THE NEGLIGENCE OF THE
              &ldquo;RELEASEES&rdquo; NAMED BELOW;
            </p>
            <p>
              d. There may be OTHER RISKS NOT KNOWN TO ME or that are not
              readily foreseeable at this time.
            </p>
            <p>&#8203;</p>
            <p>
              3. I ACCEPT SUCH RISKS AND RESPONSIBILITIES FOR THE LOSSES AND/OR
              DAMAGES FOLLOWING SUCH INJURY, DISABILITY, PARALYSIS, OR DEATH,
              even if caused, in whole or in part, by the NEGLIGENCE OF THE
              &ldquo;RELEASEES&rdquo; NAMED BELOW.
            </p>
            <p>&#8203;</p>
            <p>
              4. I HEREBY DISCHARGE AND COVENANT NOT TO SUE the promoters,
              participants, of&#64257;cials, car owners, drivers, crew members,
              rescue personnel, sponsors, advertisers, owners and lessees of
              premises used to conduct the EVENT(S), premises, and event
              inspectors, underwriters, consultants, and others who give
              recommendations, directions, or instructions or engage in risk
              evaluation or loss control activities regarding the premises or
              EVENT(S) and each of them, their directors, of&#64257;cers,
              agents, and employees, all for the purposes herein referred to as
              &ldquo;Releasees,&rdquo; FROM ALL LIABILITY TO THE UNDERSIGNED,
              his/her personal representatives, assigns, heirs, and next of kin
              FOR ANY AND ALL LOSS OR DAMAGE, AND ANY CLAIM OR DEMANDS THERE
              FORE ON ACCOUNT OF INJURY TO THE PERSON OR PROPERTY OR RESULTING
              IN DEATH OF THE UNDERSIGNED ARISING OUT OF OR RELATED TO THE
              EVENT(S), WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES OR
              OTHERWISE.
            </p>
            <p>&#8203;</p>
            <p>
              ADMISSION: Tickets to a Legends Rally Event are required to
              participate. Any Ticket is the sole property of the Purchaser
              only. The Purchaser may register one individual to participate per
              ticket purchased. By registering an individual as a Participant in
              the event, Purchaser verifies and warrants that the individual is
              Purchaser&rsquo;s guest, successor, agent and assign. Purchaser is
              solely responsible for the conduct, acts and omissions of any of
              Purchaser&rsquo;s Participants and agrees to indemnify and hold
              harmless Legends Rally, Legends Rally and their successors, agents
              and assigns from any actions, claims, causes of action, and
              damages resulting from any incident that is related to Purchaser
              or Purchaser&rsquo;s Participant. Legends Rally and Legends Rally
              have no privity with any Purchaser&rsquo;s Participant.
            </p>
            <p>
              REFUNDS FOR Legends Rally EVENTS: Ticket prices are NON REFUNDABLE
              unless otherwise stated. Under no circumstances shall Purchaser
              receive a refund for any ticket that is lost, stolen or in the
              event that Purchaser or any Purchaser&rsquo;s Participant attends
              an event.
            </p>
            <p>
              REFUNDS FOR NON-Legends Rally EVENTS: Please refer to the event
              organizer's policy for any ticket refunds.
            </p>
            <p>
              BY AGREEING TO &quot;REGISTER&quot; ON THE Legends Rally MOBILE
              APP YOU ARE ALSO ACCEPTING THE FOLLOWING WAIVER OF LIABILITY
              WRITTEN BELOW SPECIFICALLY FOR THE Legends Rally RALLY AND Legends
              Rally'S BULLRUN GT.
            </p>
            <p></p>
            <p>Legends Rally &ndash;</p>
            <p>Terms &amp; Conditions of Registration &amp; Participation</p>
            <p>
              Any participants of the Legends Rally will be asked to sign this
              agreement before rally start. Any Participant/Pilot or
              Passenger/Co-pilot of the Legends Rally must be 21 years of age or
              over. As a Pilot/Driver of the Rally, you must possess a valid,
              current and unrestricted US Driver&rsquo;s License.
            </p>
            <p>
              While the Legends Rally is geared toward exotics and high-end
              vehicles, our team is looking for a broad and unique collection of
              diverse vehicles with different makes, models and years. The
              Legends Rally is only open to seven Legends Rally classes (Exotic,
              Luxury, Sport, Tuner, Muscle, XL, Classic). Total available spaces
              are limited and subject to the discretion of Legends Rally.
            </p>
            <p>
              By completing this application and participating in the Legends
              Rally, you (A) acknowledge and agree to all terms and conditions
              contained herein; and (B) agree to pay the entire registration
              fee, unless otherwise arranged for and approved by Maclevin
              Ventures LLC. All entry fee payments are final and non-refundable.
              If you are unable to complete any part of the Rally, stay at a
              provided venue, or partake in a planned activity for any reason
              whatsoever, you are not entitled to any refund of the entry fee
              whatsoever.
            </p>
            <p>&#8203;</p>
            <p>
              The Legends Rally is a road trip and not a race. As a participant
              you must not compete in any manner with other participants. You
              must always operate your vehicle safely and legally. You must not
              drive if your ability to do so safely is in any way shape or form
              compromised. This may be due, but not limited to: the influence of
              alcohol, illegal substances, illness, lack of sleep and/or rest,
              your surroundings, and/or other contributing factors. You must not
              place bets of any kind in relation to the Rally. During the Rally,
              you must not drive your vehicle in a manner that puts other
              participants, the general driving public, pedestrians, and any
              other persons, law enforcement, or property at risk in any way
              whatsoever.
            </p>
            <p>
              As a Legends Rally participant, you agree to comply with all
              applicable laws and regulations of the Country, State, County and
              City in which you are present while driving on the Rally. Laws and
              regulations include, but are not limited to: local laws, speed
              regulations and speed limits, laws and regulations relating to
              your license to operate and/or possess a vehicle, laws and
              regulations relating to the safety and registration of your
              vehicle, laws and regulations relating to the possession and use
              of alcohol, drugs, firearms, or other prohibited substances, and
              insurance laws and regulations relating to the operation of your
              vehicle. As a participant, you must be completely sober while
              operating a motor vehicle at any point in time of the Rally. You
              must not drive under the influence of alcohol or any other
              intoxicating or illegal substances. You must not consume alcohol
              or any other illegal or intoxicating substances while operating a
              vehicle on the Rally. The Legends Rally Staff reserves the right
              to prohibit you from participating and/or continuing in the Rally
              for any violation of the foregoing or of any terms and conditions
              contained herein. All participants are required to wear a badge at
              all times of the Rally.
            </p>
            <p>&#8203;</p>
            <p>
              Maclevin Ventures LLC, its members, partners, employees,
              investors, agents, staff, media team, attorneys, consultants and
              volunteers accept no responsibility for, and shall not be liable
              to any participant for the consequences of a participant&rsquo;s
              actions in participating in the Rally, including but not limited
              to being cited, prosecuted, charged, or prohibited from further
              participation in the Rally arising from the improper conduct of a
              participant at any location during the Rally.
            </p>
            <p>&#8203;</p>
            <p>
              As a participant you must obtain and/or maintain appropriate
              insurance covering your participation in the Rally. You must
              provide proof of such insurance in a form satisfactory to the
              Legends Rally staff should they request such documentation.
              Appropriate insurance includes, without limitation, third party
              liability, liability coverage for each Participant, medical
              insurance, damage to property and life insurance, and insurance
              covering Participant vehicle&rsquo;s participation in the Rally.
              As a Pilot/Driver, you must have a legal and valid vehicle
              registration with your name matching the name on the registration
              for your participating vehicle.
            </p>
            <p>
              You will indemnify Maclevin Ventures LLC and the Legends Rally
              Staff against any and all claims, lawsuits or causes of action of
              any kind arising from the Rally and brought by any third party as
              a result of the participant&rsquo;s actions, omissions or
              participation in the Rally, including but not limited to
              participants violation of any Federal, State, County or City laws,
              ordinances or regulations, or Participant&rsquo;s breach of any
              terms and conditions contained herein.
            </p>
            <p>&#8203;</p>
            <p>
              Maclevin Ventures LLC and the Legends Rally Staff is not
              responsible if it cannot fulfill its obligations due to acts of
              God, such as, but not limited to: earthquakes, floods, fires,
              lightning, revolutions, terrorist acts, tornadoes, hurricanes, and
              other forces of nature beyond our control.
            </p>
            <p>&#8203;</p>
            <p>
              As a participant, you agree to have the &ldquo;Legends
              Rally&rdquo; logo and any other logos designated by Legends Rally
              Staff, including but not limited to official Rally sponsors,
              attached to your vehicle during the entire Rally. All logos must
              be kept clean and readily visible and you may not alter, obscure,
              or damage such logos. Maclevin Ventures LLC and the Triple Crown
              Staff will not be responsible for claims of damage to vehicle
              finishes as a result of the application or removal of such logos.
            </p>
            <p>&#8203;</p>
            <p>
              Personal sponsorship for Participant&rsquo;s vehicle or
              Participant&rsquo;s clothing is only permitted with the prior
              written consent of Legends Rally staff, which may be withheld in
              the sole discretion of Legends Rally staff. Legends Rally Staff
              reserves the right to refuse unauthorized sponsored vehicles from
              participating in the rally.
            </p>
            <p>&#8203;</p>
            <p>
              Maclevin Ventures LLC reserves the right to refuse participation
              in or to cancel the Rally at any time for any reason whatsoever.
            </p>
            <p>
              In case of the cancellation of the Rally or the refusal to allow
              participant to participate, no refunds of the entry fee will be
              given.
            </p>
            <p>
              As a participant, you agree and consent to be photographed and to
              be included in any promotional and social media material for
              Legends Rally and/or the Legends Rally. You agree that any such
              photograph is the sole property of Legends Rally and/or the
              Legends Rally including, but not limited to: photos, videos,
              advertisements, and marketing material. You hereby waive any and
              all rights of publicity or privacy and authorize Maclevin Ventures
              LLC and the Legends Rally staff full permission to utilize any of
              your and/or your vehicle&rsquo;s appearances in the Rally in any
              way Maclevin Ventures LLC sees fit, through any media in
              perpetuity. You agree that Maclevin Ventures LLC has the sole
              discretion to use any photographs, videos, or any other footage in
              which you may appear, and that Maclevin Ventures LLC has the sole
              discretion to use, and/or edit any media material resulting from
              the Rally. As a participant, you further consent to the use of
              your image, name, voice, and biographical material by Maclevin
              Ventures LLC for any marketing and/or promotional material as it
              sees fit. As a participant, you agree to release Maclevin Ventures
              LLC, and the Legends Rally Staff from any and all claims which you
              have, or may have for the invasion of privacy, defamation or any
              other cause of action arising out of the production, distribution,
              exhibition of photographs, video or photo material.
            </p>
            <p>&#8203;</p>
            <p>
              Participants are not allowed to take photographs and film footage
              of the Rally, its participants, or its vehicles for anything other
              than non-commercial purposes. As a participant, you are not
              allowed to reproduce, or use video or photographic material of the
              rally on any printed material, any website, or any other social
              media form without first obtaining the prior consent of Maclevin
              Ventures LLC. Should you as a participant deliver to Legends Rally
              or the Legends Rally staff any media material, including, but not
              limited to pictures or videos, you agree to assign (by the way of
              present assignment of future copyright) all of your rights for any
              worldwide copyright and all intellectual rights for the material
              delivered to Maclevin Ventures LLC.
            </p>
            <p>&#8203;</p>
            <p>
              You are not allowed to use the Legends Rally logo and any other
              logos designated by Legends Rally staff for placement on your
              vehicle during the Rally or any website or in printed materials
              without obtaining the prior written consent from Maclevin Ventures
              LLC.
            </p>
            <p>
              Maclevin Ventures LLC reserves the right to change these terms and
              conditions at any time. As a participant you shall be deemed to
              have accepted the amended terms and conditions by participating in
              the Rally.
            </p>
            <p>
              The terms and conditions, this agreement and your participation in
              the rally shall be governed in accordance with the laws of the
              State of Texas and you exclusively submit to the jurisdiction of
              the Courts of the State of Texas. By completing the entry form,
              participating in the Rally and accepting these terms and
              conditions, you are entering into a legally binding agreement with
              Maclevin Ventures LLC to participate in the Rally on these terms
              and conditions.
            </p>
            <p>
              You understand that while Maclevin Ventures LLC and the Legends
              Rally Staff will try everything in their power to keep your
              personal information secure. Maclevin Ventures LLC does not accept
              any responsibility should loss or theft of this information occur.
              Participant consents to its personal details being passed on to
              other third parties that Maclevin Ventures LLC deems appropriate.
            </p>
            <p>
              A. Risks Associated with Participation: As a participant, you
              understand and acknowledge that participation in the Rally is
              potentially dangerous and that there are various risks associated
              with participation. Such risks and dangers include, but are not
              limited to: accidents, collisions with vehicles, pedestrians,
              other drivers, and/or fixed or moving objects. Further, as a
              participant you understand and realize that participation in the
              Rally may result in serious bodily injury and/or death to you,
              your co-pilot or others. You understand that the route of the
              Rally will require driving on public roads upon which the hazards
              of traveling are to be expected. You realize that participation in
              the Rally will require strenuous physical exertion as participants
              will be required to drive for very long periods of time, including
              the risk of driving through inclement weather.
            </p>
            <p>
              B. Assumption of the Risks: With full knowledge and understanding
              of the risks associated with participation in the Rally, you enter
              the Rally voluntarily and fully accept and assume every such risk
              for loss, damage, or injury including death.
            </p>
            <p>
              C. Compliance with Terms and Conditions of Entry: You agree to
              comply with all the terms and conditions set forth herein. The
              Legends Rally Staff will use reasonable efforts to ensure that all
              other participants abide by the same terms and conditions of entry
              but cannot guarantee that the participants will abide by these
              terms and conditions.
            </p>
            <p>
              D. Release of Liability: In consideration for being permitted to
              participate in the Rally, you hereby waive, release, and forever
              discharge, any and all rights and/or claims which you have, may
              have, or may hereafter accrue against Maclevin Ventures LLC, the
              Legends Rally Staff (and its officers, directors, employees,
              consultants, agents) and Rally Sponsors for any and all damages,
              injuries (including death), and/or claims which may be sustained
              by a participant or its vehicle directly or indirectly arising out
              of Participant&rsquo;s participation in the Rally (including the
              application of emergency or medical services at the Rally) even if
              the damages, injuries (including death), and/or claims are caused
              by the negligence of those persons listed above or otherwise
              nothing in these Terms and Conditions shall be construed as
              excluding or limiting Legends Rally&rsquo; s liability for
              personal injury or death arising from its negligence or the
              negligence of persons under its control. Further, you agree not to
              sue Maclevin Ventures LLC, the Triple Crown Staff (and its
              officers, directors, employees, consultants, agents) and Rally
              sponsors for any and/or all damages, injuries including death,
              and/or claims which may be sustained by you directly or indirectly
              arising out of your participation in the Rally. This release and
              discharge includes, but is not limited to, claims for personal
              injury, death, breach of contract, lost wages, property damage,
              economic loss, contribution indemnity, indemnity, punitive
              damages, negligence, or any other legally recognizable claims
              arising out of Participant&rsquo;s participation in the Rally
              including all legal costs associated with such claims.
            </p>
            <p>
              E. Indemnity: You hereby agree to release, defend, indemnify, and
              hold harmless Maclevin Ventures LLC and Rally sponsors and
              licensees and assignees from and against any and all claims,
              demands, damages, suits, liabilities or actions arising directly
              or indirectly out of your actions or otherwise from your
              participation in the Rally whether made or claimed during or after
              the Rally, including all associated costs and expenses and any
              amounts which the Legends Rally Staff may pay as a settlement or
              compromise of any such claims or liabilities
            </p>{" "}
          </section>

          <footer>
            <p>
              Please note that these liability waiver is subject to change, and
              any updates will be communicated through our official channels.
            </p>
            <p>
              Thank you for using Legends Rally Official. We appreciate your
              understanding and cooperation.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Liability;
