import React from "react";
import "../checkout/CheckoutStyles.css";

const CollectJSSection = () => {
  // Created the 3 divs for the CollectJS iframes
  return (
    <React.Fragment>
      <div style={{ height: 10 }} />
      <div id="ccnumber" />
      <div style={{ height: 10 }} />
      <div id="ccexp" />
      <div style={{ height: 10 }} />
      <div id="cvv" />
      <div style={{ height: 10 }} />
    </React.Fragment>
  );
};
export default CollectJSSection;
