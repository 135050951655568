import React from "react";
import { Checkmark } from "react-checkmark";
const PaymentSuccessful = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        fontSize: 15,
      }}
    >
      <div style={{ width: "80%", textAlign: "center" }}>
        Thank you, your registration is now complete. Welcome to the Legends '24
        Family!
      </div>
      <div
        style={{
          width: "70%",

          textAlign: "center",
          fontSize: 10,
          padding: 10,
        }}
      >
        <br />
        <div>
          DON’T FORGET TO DM US @legendsrally A VIDEO OR IMAGE OF YOUR VEHICLE
          SO WE CAN ANNOUNCE YOUR TEAM ON SOCIAL MEDIA!
        </div>
        <br />
        <div>
          You will receive additional information via email as we approach
          launch date.
        </div>
        <br />
        <div>
          Feel free to reach out to our team at any time with questions.
        </div>
        <div>Let’s Rally!!</div>
        <br />
        <div>~Daniel Mac and the Legends Team</div>
      </div>
      <br />
      <div>
        <Checkmark size="medium" />
      </div>
    </div>
  );
};

export default PaymentSuccessful;
