import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Checkmark } from "react-checkmark";
import PaymentStatus from "../payment/PaymentStatus";
const CheckoutResult = () => {
  return <PaymentStatus />;
};

export default CheckoutResult;
