import React from "react";

function ReturnPolicy() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div
        className="card"
        style={{
          display: "flex",
          flex: 0.9,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 25,
          backgroundColor: "white",
          opacity: 1,
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          <header style={{ textAlign: "center" }}>
            <h1>Return Policy for Legends Rally Official</h1>
            <p>A service provided by Maclevin Ventures LLC</p>
          </header>

          <section>
            <h2>1. Eligibility for Returns:</h2>
            <p>
              Tickets purchased through Legends Rally Official are eligible for
              a return if the event is canceled, rescheduled, or if there is a
              change in the event details that significantly impacts your
              ability to attend.
            </p>

            <h2>2. Refund Requests:</h2>
            <p>
              Refund requests must be submitted within 7 days of the date of
              purchase or before 30 days of the day of the event or within 7
              days of the date of the announcement of any changes to the event.
            </p>

            <h2>3. Cancellation of Events:</h2>
            <p>
              In the case of event cancellation, a full refund will be issued to
              the original payment method used for the purchase.
            </p>

            <h2>4. Rescheduled Events:</h2>
            <p>
              If an event is rescheduled, your ticket will be automatically
              valid for the new date. If you are unable to attend the
              rescheduled date, you may request a refund.
            </p>

            <h2>5. Change in Event Details:</h2>
            <p>
              If there is a significant change in the event details (e.g.,
              venue, time), and this change adversely affects your ability to
              attend, you may request a refund.
            </p>

            <h2>6. How to Request a Refund:</h2>
            <p>
              Refund requests can be submitted through the Legends Rally
              Official platform. Please provide your order number and a brief
              explanation of the reason for the refund.
            </p>

            <h2>7. Refund Processing Time:</h2>
            <p>
              Refunds will be processed within 7 days of receiving a valid
              refund request.
            </p>

            <h2>8. Non-Refundable Situations:</h2>
            <p>
              No refunds will be issued for tickets if the event proceeds as
              scheduled and there are no significant changes.
            </p>

            <h2>9. Contact Information:</h2>
            <p>
              If you have any questions or concerns regarding our return policy,
              please contact our customer support at{" "}
              <a href="mailto:info@legendsrallyofficial.com">
                info@legendsrallyofficial.com
              </a>
            </p>
          </section>

          <footer>
            <p>
              Please note that this return policy is subject to change, and any
              updates will be communicated through our official channels.
            </p>
            <p>
              Thank you for choosing Legends Rally Official. We appreciate your
              understanding and cooperation.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default ReturnPolicy;
