import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useLocation, Navigate } from "react-router-dom";
import UploadAndDisplayImage from "../images/UploadImage";
import "../checkout/CheckoutStyles.css";

const Home = (props) => {
  const location = useLocation();
  const history = useNavigate();
  useEffect(() => {
    if (location.state && location.state.formState) {
      setFormState(location.state.formState);
    }
  }, [location]);
  const [formState, setFormState] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Make: "",
    Model: "",
    Year: "",
    Discount: "",
    Room: "Single Bed",
    Passenger: "",
    Instagram: "",
    Shirt: "",
    Team: "",
    Image: null,
  });

  const locationParams = {
    pathname: "/checkout",
    state: Object.assign({}, { formState }, location.state),
  };
  const handleChange = (e) => {
    setFormState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  if (
    true ||
    (location.state &&
      location.state.checkCode &&
      location.state.preRegistrationCode)
  ) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <div
          className="card"
          style={{
            display: "flex",
            flex: 0.9,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 25,
            backgroundColor: "white",
            opacity: 1,
          }}
        >
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h2>Legends Rally 2024!</h2>
            <div>Influencers + Celebs + Supercars</div>
            <div>March 1-3, 2024</div>
            <div>San Diego - Scottsdale - Vegas</div>
            <h2>Registration Open</h2>
            <div>Limited tickets available</div>
            <div>
              Email inquiries to{" "}
              <a href="mailto:legends@itsdanielmac.com">
                legends@itsdanielmac.com
              </a>
            </div>
            <h2>$3,999 Entry Fee</h2>
            <div
              style={{
                width: "50%",
                minWidth: 300,
                justifyContent: "center",
                display: "flex",
              }}
            >
              <ul
                style={{
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <li>1 vehicle limit of 2 people per vehicle</li>
                <li>Limited edition Legends Rally apparel</li>
                <li>Legends Rally swag bags</li>
                <li>San Diego start grid and fan experience</li>
                <li>Police escorts</li>
                <li>Professionally installed graphics</li>
                <li>Rally media crew</li>
                <li>Lunch accommodations (2 days)</li>
                <li>Dinner accommodations (2 nights)</li>
                <li>1 night stay at top Scottsdale resort</li>
                <li>1 night stay at top Vegas resort</li>
                <li>Finale at top Vegas club</li>
                <li>Professionally curated driving routes</li>
                <li>Sunday awards ceremony</li>
              </ul>
            </div>
          </div>
          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <form
              style={{
                flexDirection: "column",
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
              onSubmit={() => {
                history(locationParams.pathname, {
                  state: locationParams.state,
                });
              }}
            >
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="FIRST NAME"
                  name="FirstName"
                  required={true}
                  onChange={handleChange}
                  value={formState.FirstName}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="LAST NAME"
                  name="LastName"
                  required={true}
                  onChange={handleChange}
                  value={formState.LastName}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  display: "flex",
                  width: "100%",
                }}
              >
                <input
                  type="email"
                  className="FormElement"
                  placeholder="EMAIL"
                  name="Email"
                  required={true}
                  onChange={handleChange}
                  value={formState.Email}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="VEHICLE MAKE"
                  name="Make"
                  required={true}
                  onChange={handleChange}
                  value={formState.Make}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="VEHICLE MODEL"
                  name="Model"
                  required={true}
                  onChange={handleChange}
                  value={formState.Model}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="number"
                  className="FormElement"
                  placeholder="VEHICLE YEAR"
                  name="Year"
                  required={true}
                  onChange={handleChange}
                  value={formState.Year}
                  style={{ flex: 1 }}
                ></input>
              </div>

              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="PASSENGER NAME"
                  name="Passenger"
                  required={false}
                  onChange={handleChange}
                  value={formState.Passenger}
                  style={{ flex: 1 }}
                ></input>
              </div>

              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <div
                  className="FormElement"
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <label style={{ alignSelf: "center" }}>ROOM TYPE</label>
                  <div
                    style={{
                      display: "flex",
                      flex: 0.9,
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="Room"
                      required={false}
                      onChange={handleChange}
                      value="Single Bed"
                      checked={formState.Room == "Single Bed"}
                    ></input>
                    <label>Single King</label>
                    <input
                      type="checkbox"
                      name="Room"
                      required={false}
                      onChange={handleChange}
                      value="Double Bed"
                      checked={formState.Room == "Double Bed"}
                    ></input>
                    <label>Double Queen</label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="INSTAGRAM USERNAME"
                  name="Instagram"
                  required={false}
                  onChange={handleChange}
                  value={formState.Instagram}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="SHIRT SIZES"
                  name="Shirt"
                  required={false}
                  onChange={handleChange}
                  value={formState.Shirt}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="TEAM NAME"
                  name="Team"
                  required={false}
                  onChange={handleChange}
                  value={formState.Team}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  className="FormElement"
                  placeholder="DISCOUNT CODE"
                  name="Discount"
                  required={false}
                  onChange={handleChange}
                  value={formState.Discount}
                  style={{ flex: 1 }}
                ></input>
              </div>
              <UploadAndDisplayImage
                onChange={handleChange}
                value={formState.Image}
              />
              <div>
                {/* <Link to={locationParams}> */}
                <button
                  disabled={false}
                  style={{
                    margin: 30,
                    fontSize: 18,
                    height: 65,
                    width: 250,
                    borderRadius: 10,
                    border: "none",
                    background: "#188CBE",
                    cursor: "pointer",
                  }}
                >
                  Checkout
                </button>
                {/* </Link> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate path="/" />;
  }
};
export default Home;
