import React from "react";

function Terms() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div
        className="card"
        style={{
          display: "flex",
          flex: 0.9,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 25,
          backgroundColor: "white",
          opacity: 1,
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          <header style={{ textAlign: "center" }}>
            <h1>Return Policy for Legends Rally Official</h1>
            <p>A service provided by Maclevin Ventures LLC</p>

            <h2>1. Eligibility for Returns:</h2>
            <p>
              Tickets purchased through Legends Rally Official are eligible for
              a return if the event is canceled, rescheduled, or if there is a
              change in the event details that significantly impacts your
              ability to attend.
            </p>

            <h2>2. Refund Requests:</h2>
            <p>
              Refund requests must be submitted within 7 days of the date of
              purchase or before 30 days of the day of the event or within 7
              days of the date of the announcement of any changes to the event.
            </p>

            <h2>3. Cancellation of Events:</h2>
            <p>
              In the case of event cancellation, a full refund will be issued to
              the original payment method used for the purchase.
            </p>

            <h2>4. Rescheduled Events:</h2>
            <p>
              If an event is rescheduled, your ticket will be automatically
              valid for the new date. If you are unable to attend the
              rescheduled date, you may request a refund.
            </p>

            <h2>5. Change in Event Details:</h2>
            <p>
              If there is a significant change in the event details (e.g.,
              venue, time), and this change adversely affects your ability to
              attend, you may request a refund.
            </p>

            <h2>6. How to Request a Refund:</h2>
            <p>
              Refund requests can be submitted through the Legends Rally
              Official platform. Please provide your order number and a brief
              explanation of the reason for the refund.
            </p>

            <h2>7. Refund Processing Time:</h2>
            <p>
              Refunds will be processed within 7 days of receiving a valid
              refund request.
            </p>

            <h2>8. Non-Refundable Situations:</h2>
            <p>
              No refunds will be issued for tickets if the event proceeds as
              scheduled and there are no significant changes.
            </p>

            <h2>9. Contact Information:</h2>
            <p>
              If you have any questions or concerns regarding our return policy,
              please contact our customer support at{" "}
              <a href="mailto:info@legendsrallyofficial.com">
                info@legendsrallyofficial.com
              </a>
            </p>
            <h1>Terms and Conditions for Legends Rally Official</h1>
            <p>A service provided by Maclevin Ventures LLC</p>
          </header>
          <section>
            {/*
            <h2>1. Acceptance of Terms</h2>
            <p>
              By using the Legends Rally Official website or any services
              provided by Maclevin Ventures LLC, you agree to comply with and be
              bound by these Terms and Conditions.
            </p>

            <h2>2. Ticket Purchase and Use</h2>
            <p>
              When purchasing tickets through Legends Rally Official, you agree
              to adhere to the terms specified in the Return Policy. Tickets are
              for personal use only and may not be resold or transferred without
              explicit permission from Maclevin Ventures LLC.
            </p>

            <h2>3. Event Changes and Cancellations</h2>
            <p>
              Legends Rally Official reserves the right to change event details,
              including but not limited to venue, date, and time. In the event
              of cancellation, you will be subject to the terms outlined in the
              Return Policy.
            </p>

            <h2>4. Intellectual Property</h2>
            <p>
              All content and materials available on Legends Rally Official,
              including but not limited to text, graphics, logos, images, and
              audio clips, are the property of Maclevin Ventures LLC and are
              protected by applicable intellectual property laws.
            </p>

            <h2>5. User Responsibilities</h2>
            <p>
              Users are responsible for maintaining the confidentiality of their
              account information and for all activities that occur under their
              account. Legends Rally Official is not responsible for any
              unauthorized use of accounts.
            </p>

            <h2>6. Prohibited Activities</h2>
            <p>
              Users agree not to engage in any activities that may interfere
              with the proper functioning of Legends Rally Official, including
              but not limited to hacking, data mining, or the use of automated
              scripts.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p>
              Legends Rally Official and Maclevin Ventures LLC shall not be
              liable for any direct, indirect, incidental, special, or
              consequential damages resulting from the use or inability to use
              the services provided.
            </p>

            <h2>8. Contact Information:</h2>
            <p>
              If you have any questions or concerns regarding our Terms and
              Conditions, please contact our customer support at{" "}
              <a href="mailto:info@legendsrallyofficial.com">
                info@legendsrallyofficial.com
              </a>{" "}
            </p>
            */}
            <p> The Maclevin Ventures LLC Terms of Service</p>

            <p></p>

            <p>
              These terms of service constitute a legally binding agreement (the
              &ldquo;Agreement&rdquo;) between you and Maclevin Ventures, LLC
              (&ldquo;LegendsRally&rdquo; &ldquo;we,&rdquo; &ldquo;us&rdquo; or
              &ldquo;our&rdquo;) governing your use of the LegendsRally
              application, website, and technology platform (collectively, the
              &ldquo;LegendsRally Platform&rdquo;).
            </p>

            <p></p>

            <p>
              PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN
              HOW CLAIMS YOU AND LegendsRally HAVE AGAINST EACH OTHER CAN BE
              BROUGHT (SEE SECTION 17 BELOW). THESE PROVISIONS WILL, WITH
              LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST
              LegendsRally TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL
              BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR
              REPRESENTATIVE ACTION OR PROCEEDING. AS A VENUE, YOU HAVE AN
              OPPORTUNITY TO OPT OUT OF ARBITRATION WITH RESPECT TO CERTAIN
              CLAIMS AS PROVIDED IN SECTION 17.
            </p>

            <p></p>

            <p>
              By entering into to this Agreement, you expressly acknowledge that
              you understand this Agreement (including the dispute resolution
              and arbitration provisions Section 17) and accept all of its
              terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS
              OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE LegendsRally
              PLATFORM.
            </p>

            <p></p>

            <p>The LegendsRally Platform</p>

            <p>
              The LegendsRally Platform provides a marketplace where persons who
              seek the ability to valet their luxury automobile
              (&ldquo;Subscribers&rdquo;) at certain destinations can be matched
              with various entities, destinations, car clubs or events with the
              ability to valet their vehicle up front (&ldquo;Venues&rdquo;).
              Venues and Subscribers are collectively referred to herein as
              &ldquo;Users,&rdquo; and each User shall create a User account
              that enables access to the LegendsRally Platform. For purposes of
              this Agreement, the reservation services provided by the
              application to secure up front parking at participating third
              party Venues shall be referred to collectively as the
              &ldquo;Services&rdquo;. Any decision by a User to offer or accept
              Services is a decision made in such User&rsquo;s sole discretion.
              Each Service provided by a Venue to a Subscriber shall constitute
              a separate agreement between such persons.
            </p>

            <p></p>

            <p>Reservation Services</p>

            <p>
              The availability of reservations is determined at the time of the
              Users query. Once a reservation is made by the Subscriber through
              LegendsRally&rsquo;s website or Application, LegendsRally shall
              provide confirmation of the reservation. LegendsRally at no time
              guarantees that a reservation will be available upon arrival.
            </p>

            <p>Modification to the Agreement</p>

            <p></p>

            <p>
              In the event LegendsRally modifies the terms and conditions of
              this Agreement, such modifications shall be binding on you only
              upon your acceptance of the modified Agreement. LegendsRally
              reserves the right to modify any information referenced in the
              hyperlinks from this Agreement from time to time, and such
              modifications shall become effective upon posting. Continued use
              of the LegendsRally Platform or Services after any such changes
              shall constitute your consent to such changes. Unless material
              changes are made to the arbitration provisions herein, you agree
              that modification of this Agreement does not create a renewed
              opportunity to opt out of arbitration (if applicable).
            </p>

            <p></p>

            <p>Eligibility</p>

            <p>
              The LegendsRally Platform may only be used by individuals who can
              form legally binding contracts under applicable law. The
              LegendsRally Platform is not available to children (persons under
              the age of 18), persons under the age of 21 or Users who have had
              their User account temporarily or permanently deactivated. By
              becoming a User, you represent and warrant that you are at least
              21 years old and that you have the right, authority and capacity
              to enter into and abide by the terms and conditions of this
              Agreement. You may not allow other persons to use your User
              account, and you agree that you are the sole authorized user of
              your account.
            </p>

            <p></p>

            <p>Charges &amp; Subscription</p>

            <p>
              As a User you agree to pay the amounts charged for your use of the
              LegendsRally Platform and Services (&ldquo;Charges&rdquo;).
              Charges include Fares and other applicable fees, tolls,
              surcharges, and taxes as set forth in your market&rsquo;s
              LegendsRally page (www.LegendsRally.com). LegendsRally has the
              authority and reserves the right to determine and modify pricing
              by posting applicable pricing terms to LegendsRally&rsquo;s web
              page. Pricing may vary based on the type of service you request
              (e.g., LegendsRally, bronze, LegendsRally Silver, LegendsRally
              Gold, LegendsRally Platinum) as described LegendsRally page. You
              are responsible for reviewing the applicable LegendsRally web page
              and shall be responsible for all Charges incurred under your User
              account regardless of your awareness of such Charges or the
              amounts thereof.
            </p>

            <p>Fees and Other Charges.</p>

            <p>
              Service Fee. LegendsRally may assess a per-park &ldquo;Service
              Fee&rdquo; to support the LegendsRally Platform and related
              services provided to you by LegendsRally. The amount of the
              Service Fee may vary by Venue but shall be retained by
              LegendsRally in its entirety.
            </p>

            <p>
              Cancellation Fee. After requesting a parking spot you may cancel
              it through the app, but note that in certain cases a cancellation
              fee may apply. You may also be charged if you fail to show up
              after requesting a &ldquo;up front&rdquo; parking spot. Please
              check out our Help Center to learn more about LegendsRally&rsquo;s
              cancellation policy, including applicable fees.
            </p>

            <p>
              Tips. Subscriber may elect to tip your Valet in cash or through
              the LegendsRally application. Any tips will be provided entirely
              to the applicable Valet.
            </p>

            <p> General.</p>

            <p>
              Facilitation of Charges. All Charges are facilitated through a
              third-party payment processing service (e.g., Stripe, Inc., or
              Braintree, a division of PayPal, Inc.). LegendsRally may replace
              its third-party payment processing services without notice to you.
              Charges shall only be made through the LegendsRally Platform. With
              the exception of tips, cash payments are strictly prohibited.
            </p>

            <p>
              No Refunds. All Charges are non-refundable. This no-refund policy
              shall apply at all times regardless of your decision to terminate
              usage of the LegendsRally Platform, any disruption to the
              LegendsRally Platform or Services, or any other reason whatsoever.
            </p>

            <p>
              Credit Card Authorization. Upon addition of a new payment method
              or each valet request, LegendsRally may seek authorization of your
              selected payment method to verify the payment method, ensure the
              cost of parking will be covered, and protect against unauthorized
              behavior. The authorization is not a charge, however, it may
              reduce your available credit by the authorization amount until
              your bank&rsquo;s next processing cycle. Should the amount of our
              authorization exceed the total funds on deposit in your account,
              you may be subject to overdraft of NSF charges by the bank issuing
              your debit or check card. We cannot be held responsible for these
              charges and are unable to assist you in recovering them from your
              issuing bank.
            </p>

            <p></p>

            <p>LegendsRally Communications</p>

            <p>
              By becoming a User, you agree to receive communications from us,
              including via e-mail, text message, calls, and push notifications.
              You agree that texts, calls or prerecorded messages may be
              generated by automatic telephone dialing systems. Communications
              from LegendsRally and its affiliated companies, may include but
              are not limited to: operational communications concerning your
              User account or use of the LegendsRally Platform or Services,
              updates concerning new and existing features on the LegendsRally
              Platform, communications concerning promotions run by us or our
              third- party partners, and news concerning LegendsRally and
              industry developments. Standard text messaging charges applied by
              your cell phone carrier will apply to text messages we send.
            </p>

            <p></p>

            <p>
              IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU CAN UNSUBSCRIBE
              FROM OUR PROMOTIONAL EMAIL LIST BY FOLLOWING THE UNSUBSCRIBE
              OPTIONS IN THE PROMOTIONAL EMAIL ITSELF. IF YOU WISH TO OPT OUT OF
              PROMOTIONAL CALLS OR TEXTS, YOU MAY TEXT &ldquo;END&rdquo; TO
              46080 FROM THE MOBILE DEVICE RECEIVING THE MESSAGES. YOU
              ACKNOWLEDGE THAT YOU ARE NOT REQUIRED TO CONSENT TO RECEIVE
              PROMOTIONAL TEXTS OR CALLS AS A CONDITION OF USING THE
              LegendsRally PLATFORM OR THE SERVICES. IF YOU WISH TO OPT OUT OF
              ALL TEXTS OR CALLS FROM LegendsRally (INCLUDING OPERATIONAL OR
              TRANSACTIONAL TEXTS OR CALLS), YOU CAN TEXT THE WORD
              &ldquo;STOPALL&rdquo; TO 46080 FROM THE MOBILE DEVICE RECEIVING
              THE MESSAGES, HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING
              ALL TEXTS MAY IMPACT YOUR USE OF THE LegendsRally PLATFORM OR THE
              SERVICES.
            </p>

            <p></p>

            <p>Your Information</p>

            <p>
              Your Information is any information you provide, publish or post
              to or through the LegendsRally Platform (including any profile
              information you provide) or send to other Users (including via
              in-application feedback, any email feature, or through any
              LegendsRally-related Facebook, Twitter or other social media
              posting) (your &ldquo;Information&rdquo;). You consent to us using
              your Information to create a User account that will allow you to
              use the LegendsRally Platform and participate in the Services. Our
              collection and use of personal information in connection with the
              LegendsRally Platform and Services is as provided in
              LegendsRally&rsquo;s Privacy Policy located at
              www.LegendsRally.com/privacy. You are solely responsible for your
              Information and your interactions with other members of the
              public, and we act only as a passive conduit for your online
              posting of your Information. You agree to provide and maintain
              accurate, current and complete information and that we and other
              members of the public may rely on your Information as accurate,
              current and complete. To enable LegendsRally to use your
              Information, you grant to us a non-exclusive, worldwide,
              perpetual, irrevocable, royalty-free, transferable, sub-licensable
              (through multiple tiers) right and license to exercise the
              copyright, publicity, and database rights you have in your
              Information, and to use, copy, perform, display and distribute
              such Information to prepare derivative works, or incorporate into
              other works, such Information, in any media now known or not
              currently known. LegendsRally does not assert any ownership over
              your Information; rather, as between you and LegendsRally, subject
              to the rights granted to us in this Agreement, you retain full
              ownership of all of your Information and any intellectual property
              rights or other proprietary rights associated with your
              Information.
            </p>

            <p></p>

            <p>
              You may be able to create or log-in to your LegendsRally User
              account through online accounts you may have with third party
              social networking sites (each such account, an &quot;SNS
              Account&quot;). By connecting to LegendsRally through an SNS
              Account, you understand that LegendsRally may access, store, and
              make available any SNS Account content according to the permission
              settings of your SNS Account (e.g., friends, mutual friends,
              contacts or following/followed lists (the &ldquo;SNS
              Content&rdquo;)). You understand that SNS Content may be available
              on and through the LegendsRally Platform to other Users. Unless
              otherwise specified in this Agreement, all SNS Content, if any,
              shall be considered to be your Information.
            </p>

            <p>&#8203;</p>

            <p>USER-CREATED EVENT</p>

            <p>
              For any event created by users, LegendsRally reserves the right to
              cancel, deactivate and/or delete the event for any reason
              LegendsRally sees fit. These reasons may include and are not
              limited to:
            </p>

            <p> 1. Inappropriate content</p>

            <p>
              {" "}
              2. Events that can or might be illegal in nature or promote
              illegal activity
            </p>

            <p>
              {" "}
              3. Events that use the LegendsRally platform to redirect
              LegendsRally users to third party registration platforms as a mean
              to solicit user information and data.
            </p>

            <p></p>

            <p>
              &quot;Official LegendsRally event&quot; (&quot;LegendsRally
              Event&quot;) does not constitute an event organized by
              LegendsRally, nor does it indicate LegendsRally having any legal
              representation or assume any liability with said event. The label
              &quot;Official Event&quot; is used for marketing purposes only.
            </p>

            <p>&#8203;</p>

            <p>Promotions and Referral Programs</p>

            <p>
              LegendsRally, at its sole discretion, may make available
              promotions with different features to any Users or prospective
              Users. These promotions, unless made to you, shall have no bearing
              whatsoever on your Agreement or relationship with LegendsRally.
              LegendsRally reserves the right to withhold or deduct credits or
              benefits obtained through a promotion the event that LegendsRally
              determines or believes that the redemption of the promotion or
              receipt of the credit or benefit was in error, fraudulent,
              illegal, or in violation of the applicable promotion terms or this
              Agreement.
            </p>

            <p>
              From time to time, LegendsRally may offer you with incentives to
              refer new Users to the LegendsRally community (the &ldquo;Referral
              Program&rdquo;). These incentives may come in the form of
              LegendsRally Credits, and LegendsRally may set or change the
              incentive types, amounts, terms, restrictions, and qualification
              requirements for any incentives in its sole discretion. Your
              distribution of LegendsRally Codes and participation in the
              Referral Program is subject to this Agreement and the additional
              Referral Program Rules.
            </p>

            <p>&#8203;</p>

            <p>Refunds</p>

            <p>
              All ticket purchases through the LegendsRally platform are
              non-refundable by default, unless otherwise stated. This applies
              to official LegendsRally events, as well as user created events,
              not affiliated with LegendsRally. Should you have additional
              questions, you can contact our support team at
              info@legendsrallyofficial.com.
            </p>

            <p>&#8203;</p>

            <p>Restricted Activities</p>

            <p>
              With respect to your use of the LegendsRally Platform and your
              participation in the Services, you agree that you will not:
            </p>

            <p>impersonate any person or entity;</p>

            <p>
              stalk, threaten, or otherwise harass any person, or carry any
              weapons;
            </p>

            <p>
              violate any law, statute, rule, permit, ordinance or regulation;
            </p>

            <p>
              interfere with or disrupt the Services or the LegendsRally
              Platform or the servers or networks connected to the LegendsRally
              Platform;
            </p>

            <p>
              post Information or interact on the LegendsRally Platform or
              Services in a manner which is false, inaccurate, misleading
              (directly or by omission or failure to update information),
              defamatory, libelous, abusive, obscene, profane, offensive,
              sexually oriented, threatening, harassing, or illegal;
            </p>

            <p>
              use the LegendsRally Platform in any way that infringes any third
              party&rsquo;s rights, including but not limited to: intellectual
              property rights, copyright, patent, trademark, trade secret or
              other proprietary rights or rights of publicity or privacy;
            </p>

            <p>
              post, email or otherwise transmit any malicious code, files or
              programs designed to interrupt, damage, destroy or limit the
              functionality of any computer software or hardware or
              telecommunications equipment or surreptitiously intercept or
              expropriate any system, data or personal information;
            </p>

            <p>
              forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any information transmitted through the
              LegendsRally Platform;
            </p>

            <p>
              &ldquo;frame&rdquo; or &ldquo;mirror&rdquo; any part of the
              LegendsRally Platform, without our prior written authorization or
              use meta tags or code or other devices containing any reference to
              us in order to direct any person to any other web site for any
              purpose; or
            </p>

            <p>
              modify, adapt, translate, reverse engineer, decipher, decompile or
              otherwise disassemble any portion of the LegendsRally Platform or
              any software used on or for the LegendsRally Platform;
            </p>

            <p>
              rent, lease, lend, sell, redistribute, license or sublicense the
              LegendsRally Platform or access to any portion of the LegendsRally
              Platform;
            </p>

            <p>
              use any robot, spider, site search/retrieval application, or other
              manual or automatic device or process to retrieve, index, scrape,
              &ldquo;data mine&rdquo;, or in any way reproduce or circumvent the
              navigational structure or presentation of the LegendsRally
              Platform or its contents;
            </p>

            <p>link directly or indirectly to any other web sites;</p>

            <p>
              transfer or sell your User account, password and/or identification
              to any other party
            </p>

            <p>
              discriminate against or harass anyone on the basis of race,
              national origin, religion, gender, gender identity, physical or
              mental disability, medical condition, marital status, age or
              sexual orientation, or
            </p>

            <p>
              cause any third party to engage in the restricted activities
              above.
            </p>

            <p></p>

            <p>Venue Representations, Warranties and Agreements</p>

            <p>
              By providing Services as a Venue on the LegendsRally Platform, you
              represent, warrant, and agree that:
            </p>

            <p>
              Your valet contractors or parking employees possess a valid
              Venue&rsquo;s license and are authorized and medically fit to
              operate a motor vehicle and have all appropriate licenses,
              approvals and authority to provide park and drive any vehicle
              presented by subscriber in all jurisdictions in which you provide
              Services.
            </p>

            <p>
              You will only provide Services using the vehicle that has been
              reported to, and approved by LegendsRally, and for which a
              photograph has been provided to LegendsRally.
            </p>

            <p>
              You will not make any misrepresentation regarding LegendsRally,
              the LegendsRally Platform, the Services or your status as a Venue.
            </p>

            <p>
              You will not attempt to defraud LegendsRally or Subscribers on the
              LegendsRally Platform or in connection with your provision of
              Services. If we suspect that you have engaged in fraudulent
              activity we may suspend your account indefinitely.
            </p>

            <p>
              You will pay all applicable federal, state and local taxes based
              on your provision of Services and any payments received by you.
            </p>

            <p></p>

            <p>Subscriber Representations, Warranties, and Agreements</p>

            <p>
              By providing using the Services as a Subscriber on the
              LegendsRally Platform, you represent, warrant, and agree that:
            </p>

            <p>
              You are 18 or older and possess a valid legal driver&rsquo;s
              license.
            </p>

            <p>
              You own, or have the legal right to operate the vehicle registered
              under the LegendsRally Application or website.
            </p>

            <p>
              You will only use those vehicles that have been reported to, and
              approved by LegendsRally, and for which a photograph has been
              provided to LegendsRally.
            </p>

            <p>
              Such Subscriber is named or scheduled on the insurance policy
              covering the vehicle such Subscriber uses.
            </p>

            <p>
              Such Subscriber will obey all local laws related to the matters
              set forth herein, and will be solely responsible for any violation
              of such local laws.
            </p>

            <p>
              Subscriber will not make any misrepresentation regarding
              LegendsRally, the LegendsRally Platform, the Services, or
              Subscriber&rsquo;s status as a Subscriber or engage in any other
              activity in a manner that is inconsistent with Subscriber&rsquo;s
              obligations under this Agreement.
            </p>

            <p></p>

            <p>Intellectual Property</p>

            <p>
              All intellectual property rights in the LegendsRally Platform
              shall be owned by LegendsRally absolutely and in their entirety.
              These rights include and are not limited to database rights,
              copyright, design rights (whether registered or unregistered),
              trademarks (whether registered or unregistered) and other similar
              rights wherever existing in the world together with the right to
              apply for protection of the same. All other trademarks, logos,
              service marks, company or product names set forth in the
              LegendsRally Platform are the property of their respective owners.
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback or other information
              (&ldquo;Submissions&rdquo;) provided by you to us are
              non-confidential and shall become the sole property of
              LegendsRally. LegendsRally shall own exclusive rights, including
              all intellectual property rights, and shall be entitled to the
              unrestricted use and dissemination of these Submissions for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
            </p>

            <p></p>

            <p>
              LegendsRally and other LegendsRally logos, designs, graphics,
              icons, scripts and service names are registered trademarks,
              trademarks or trade dress of LegendsRally in the United States
              and/or other countries (collectively, the &ldquo;LegendsRally
              Marks&rdquo;). If you provide Services as a Venue, LegendsRally
              grants to you, during the term of this Agreement, and subject to
              your compliance with the terms and conditions of this Agreement, a
              limited, revocable, non-exclusive license to display and use the
              LegendsRally Marks solely in connection with providing the
              Services through the LegendsRally Platform
              (&ldquo;License&rdquo;). The License is non-transferable and
              non-assignable, and you shall not grant to any third party any
              right, permission, license or sublicense with respect to any of
              the rights granted hereunder without LegendsRally&rsquo;s prior
              written permission, which it may withhold in its sole discretion.
              The LegendsRally Marks may not be used in any manner that is
              likely to cause confusion.
            </p>

            <p>
              You acknowledge that LegendsRally is the owner and licensor of the
              LegendsRally Marks, including all goodwill associated therewith,
              and that your use of the LegendsRally Marks will confer no
              additional interest in or ownership of the LegendsRally Marks in
              you but rather inures to the benefit of LegendsRally. You agree to
              use the LegendsRally Marks strictly in accordance with
              LegendsRally&rsquo;s Trademark Usage Guidelines, as may be
              provided to you and revised from time to time, and to immediately
              cease any use that LegendsRally determines to nonconforming or
              otherwise unacceptable.
            </p>

            <p></p>

            <p>
              You agree that you will not: (1) create any materials that
              incorporate the LegendsRally Marks or any derivatives of the
              LegendsRally Marks other than as expressly approved by
              LegendsRally in writing; (2) use the LegendsRally Marks in any way
              that tends to impair their validity as proprietary trademarks,
              service marks, trade names or trade dress, or use the LegendsRally
              Marks other than in accordance with the terms, conditions and
              restrictions herein; (3) take any other action that would
              jeopardize or impair LegendsRally&rsquo;s rights as owner of the
              LegendsRally Marks or the legality and/or enforceability of the
              LegendsRally Marks, including, without limitation, challenging or
              opposing LegendsRally&rsquo;s ownership in the LegendsRally Marks;
              (4) apply for trademark registration or renewal of trademark
              registration of any of the LegendsRally Marks, any derivative of
              the LegendsRally Marks, any combination of the LegendsRally Marks
              and any other name, or any trademark, service mark, trade name,
              symbol or word which is similar to the LegendsRally Marks; (5) use
              the LegendsRally Marks on or in connection with any product,
              service or activity that is in violation of any law, statute,
              government regulation or standard.
            </p>

            <p>
              Violation of any provision of this License may result in immediate
              termination of the License, in LegendsRally&rsquo;s sole
              discretion. If you create any materials bearing the LegendsRally
              Marks (in violation of this Agreement or otherwise), you agree
              that upon their creation LegendsRally exclusively owns all right,
              title and interest in and to such materials, including without
              limitation any modifications to the LegendsRally Marks or
              derivative works based on the LegendsRally Marks. You further
              agree to assign any interest or right you may have in such
              materials to LegendsRally, and to provide information and execute
              any documents as reasonably requested by LegendsRally to enable
              LegendsRally to formalize such assignment.
            </p>

            <p></p>

            <p>
              LegendsRally respects the intellectual property of others, and
              expects Users to do the same. If you believe, in good faith, that
              any materials on the LegendsRally Platform or Services infringe
              upon your copyrights, please visit Copyright Policy page for
              information on how to make a copyright complaint.
            </p>

            <p></p>

            <p>Disclaimers</p>

            <p>
              The following disclaimers are made on behalf of LegendsRally, our
              affiliates, subsidiaries, parents, successors and assigns, and
              each of our respective officers, directors, employees, agents, and
              shareholders.
            </p>

            <p></p>

            <p>
              LegendsRally does not provide transportation services, and
              LegendsRally is not a transportation carrier. It is up to the
              Venue to decide whether or not to offer valet services or parking
              to a Subscriber contacted through the LegendsRally Platform, and
              it is up to the Subscriber to decide whether or not to accept a
              parking spot from any Venue contacted through the LegendsRally
              Platform. We cannot ensure that a Venue or Subscriber will
              complete an arranged transportation service. We have no control
              over the quality or safety of the transportation that occurs as a
              result of the Services.
            </p>

            <p></p>

            <p>
              The LegendsRally Platform is provided on an &ldquo;as is&rdquo;
              basis and without any warranty or condition, express, implied or
              statutory. We do not guarantee and do not promise any specific
              results from use of the LegendsRally Platform and/or the Services,
              including the ability to provide or receive Services at any given
              location or time, or secure &ldquo;up front parking&rdquo;. We
              specifically disclaim any implied warranties of title,
              merchantability, fitness for a particular purpose and
              non-infringement. Some states do not allow the disclaimer of
              implied warranties, so the foregoing disclaimer may not apply to
              you.
            </p>

            <p></p>

            <p>
              We do not warrant that your use of the LegendsRally Platform or
              Services will be accurate, complete, reliable, current, secure,
              uninterrupted, always available, or error- free, or will meet your
              requirements, that any defects in the LegendsRally Platform will
              be corrected, or that the LegendsRally Platform is free of viruses
              or other harmful components. We disclaim liability for, and no
              warranty is made with respect to, connectivity and availability of
              the LegendsRally Platform or Services.
            </p>

            <p>
              We cannot guarantee that each Subscriber is who he or she claims
              to be. Please use common sense when using the LegendsRally
              Platform and Services, including looking at the photos of the
              Venue or Subscriber you have matched with to make sure it is the
              same individual you see in person. Please note that there are also
              risks of dealing with underage persons or people acting under
              false pretense, and we do not accept responsibility or liability
              for any content, communication or other use or access of the
              LegendsRally Platform by persons under the age of 18 in violation
              of this Agreement. We encourage you to communicate directly with
              each potential Venue or Subscriber prior to engaging in an
              arranged transportation service.
            </p>

            <p></p>

            <p>
              LegendsRally is not responsible for the conduct, whether online or
              offline, of any User of the LegendsRally Platform or Services. You
              are solely responsible for your interactions with other Users. We
              do not procure insurance for, nor are we responsible for, personal
              belongings left in the car by Venues or Subscribers. By using the
              LegendsRally Platform and participating in the Services, you agree
              to accept such risks and agree that LegendsRally is not
              responsible for the acts or omissions of Users on the LegendsRally
              Platform or participating in the Services.
            </p>

            <p></p>

            <p>
              LegendsRally expressly disclaims any liability arising from the
              unauthorized use of your User account. Should you suspect that any
              unauthorized party may be using your User account or you suspect
              any other breach of security, you agree to notify us immediately.
            </p>

            <p></p>

            <p>
              It is possible for others to obtain information about you that you
              provide, publish or post to or through the LegendsRally Platform
              (including any profile information you provide), send to other
              Users, or share during the Services, and to use such information
              to harass or harm you. We are not responsible for the use of any
              personal information that you disclose to other Users on the
              LegendsRally Platform or through the Services. Please carefully
              select the type of information that you post on the LegendsRally
              Platform or through the Services or release to others. We disclaim
              all liability, regardless of the form of action, for the acts or
              omissions of other Users (including unauthorized users, or
              &ldquo;hackers&rdquo;).
            </p>

            <p></p>

            <p>
              Opinions, advice, statements, offers, or other information or
              content concerning LegendsRally or made available through the
              LegendsRally Platform, but not directly by us, are those of their
              respective authors, and should not necessarily be relied upon.
              Such authors are solely responsible for such content. Under no
              circumstances will we be responsible for any loss or damage
              resulting from your reliance on information or other content
              posted by third parties, whether on the LegendsRally Platform or
              otherwise. We reserve the right, but we have no obligation, to
              monitor the materials posted on the LegendsRally Platform and
              remove any such material that in our sole opinion violates, or is
              alleged to violate, the law or this agreement or which might be
              offensive, illegal, or that might violate the rights, harm, or
              threaten the safety of Users or others.
            </p>

            <p></p>

            <p>
              Location data provided by the LegendsRally Platform is for basic
              location purposes only and is not intended to be relied upon in
              situations where precise location information is needed or where
              erroneous, inaccurate or incomplete location data may lead to
              death, personal injury, property or environmental damage. Neither
              LegendsRally, nor any of its content providers, guarantees the
              availability, accuracy, completeness, reliability, or timeliness
              of location data tracked or displayed by the LegendsRally
              Platform. Any of your Information, including geolocational data,
              you upload, provide, or post on the LegendsRally Platform may be
              accessible to LegendsRally and certain Users of the LegendsRally
              Platform.
            </p>

            <p></p>

            <p>
              LegendsRally advises you to use the LegendsRally Platform with a
              data plan with unlimited or very high data usage limits, and
              LegendsRally shall not responsible or liable for any fees, costs,
              or overage charges associated with any data plan you use to access
              the LegendsRally Platform.
            </p>

            <p></p>

            <p>
              This paragraph applies to any version of the LegendsRally Platform
              that you acquire from the Apple App Store. This Agreement is
              entered into between you and LegendsRally. Apple, Inc.
              (&ldquo;Apple&rdquo;) is not a party to this Agreement and shall
              have no obligations with respect to the LegendsRally Platform.
              LegendsRally, not Apple, is solely responsible for the
              LegendsRally Platform and the content thereof as set forth
              hereunder. However, Apple and Apple&rsquo;s subsidiaries are third
              party beneficiaries of this Agreement. Upon your acceptance of
              this Agreement, Apple shall have the right (and will be deemed to
              have accepted the right) to enforce this Agreement against you as
              a third party beneficiary thereof. This Agreement incorporates by
              reference Apple&rsquo;s Licensed Application End User License
              Agreement, for purposes of which, you are &ldquo;the
              end-user.&rdquo; In the event of a conflict in the terms of the
              Licensed Application End User License Agreement and this
              Agreement, the terms of this Agreement shall control.
            </p>

            <p></p>

            <p>Release</p>

            <p>
              Venues are solely responsible for their interactions with
              Subscriber and any and all claims, injuries, illnesses, damages,
              liabilities, and costs (&ldquo;Claims&rdquo;) suffered by
              Subscriber as a result of Subscriber&rsquo;s interaction with or
              visit to any Venue or from any product or service (including but
              not limited to any specials, offers, discounts, promotions or
              events) of any Venue. Users hereby release LegendsRally from any
              and all such Claims. LegendsRally provides reservation Services as
              a convenience and does not review, approve, monitor, endorse,
              warrant, or make any representations with respect to Venues.
            </p>

            <p>Indemnity</p>

            <p></p>

            <p>
              You will defend, indemnify, and hold LegendsRally including our
              affiliates, subsidiaries, parents, successors and assigns, and
              each of our respective officers, directors, employees, agents, or
              shareholders harmless from any claims, actions, suits, losses,
              costs, liabilities and expenses (including reasonable
              attorneys&rsquo; fees) relating to or arising out of your use of
              the LegendsRally Platform and participation in the Services,
              including: (1) your breach of this Agreement or the documents it
              incorporates by reference; (2) your violation of any law or the
              rights of a third party, including, without limitation, Venues,
              Subscribers, other motorists, and pedestrians, as a result of your
              own interaction with such third party; (3) any allegation that any
              materials that you submit to us or transmit through the
              LegendsRally Platform or to us infringe or otherwise violate the
              copyright, trademark, trade secret or other intellectual property
              or other rights of any third party; (4) your ownership, use or
              operation of a motor vehicle or passenger vehicle, including your
              provision of Services as a Venue; and/or (5) any other activities
              in connection with the Services. This indemnity shall be
              applicable without regard to the negligence of any party,
              including any indemnified person.
            </p>

            <p>Limitation of Liability</p>

            <p></p>

            <p>
              IN NO EVENT WILL LegendsRally, INCLUDING OUR AFFILIATES,
              SUBSIDIARIES, PARENTS, SUCCESSORS AND ASSIGNS, AND EACH OF OUR
              RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS
              (COLLECTIVELY &ldquo;LegendsRally&rdquo; FOR PURPOSES OF THIS
              SECTION), BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY,
              PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT
              LIMITED TO, DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA, LOSS
              OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT
              MAINTAINED OR TRANSMITTED BY THE LegendsRally PLATFORM, SERVICE
              INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE
              SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE LegendsRally
              PLATFORM, THE SERVICES, OR THIS AGREEMENT, HOWEVER ARISING
              INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR REPRESENTATIVES
              KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
              LegendsRally PLATFORM MAY BE USED BY YOU TO REQUEST AND SCHEDULE
              TRANSPORTATION, GOODS, OR OTHER SERVICES WITH THIRD PARTY
              PROVIDERS, BUT YOU AGREE THAT LegendsRally HAS NO RESPONSIBILITY
              OR LIABILITY TO YOU RELATED TO ANY TRANSPORTATION, GOODS OR OTHER
              SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS
              EXPRESSLY SET FORTH IN THERE TERMS. CERTAIN JURISDICTIONS MAY NOT
              ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
              LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
              EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
              ADDITIONAL RIGHTS.
            </p>

            <p></p>

            <p>Term and Termination</p>

            <p>
              This Agreement is effective upon your creation of a User account.
              This Agreement may be terminated: a) by User, without cause, upon
              seven (7) days&rsquo; prior written notice to LegendsRally; or b)
              by either Party immediately, without notice, upon the other
              Party&rsquo;s material breach of this Agreement, including but not
              limited to any breach of Section 9 or breach of Section 10(a)
              through (i) of this Agreement.
            </p>

            <p>
              In addition, LegendsRally may terminate this Agreement or
              deactivate your User account immediately in the event: (1) you no
              longer qualify to provide Services or to operate the approved
              vehicle under applicable law, rule, permit, ordinance or
              regulation; (2) you violate a terms of use as listed herein; (3)
              LegendsRally has the good faith belief that such action is
              necessary to protect the safety of the LegendsRally community or
              third parties, provided that in the event of a deactivation
              pursuant to (1)-(3) above, you will be given notice of the
              potential or actual deactivation and an opportunity to attempt to
              cure the issue to LegendsRally&rsquo;s reasonable satisfaction
              prior to LegendsRally permanently terminating the Agreement. For
              all other breaches of this Agreement, you will be provided notice
              and an opportunity to cure the breach. If the breach is cured in a
              timely manner and to LegendsRally&rsquo;s satisfaction, this
              Agreement will not be permanently terminated. Sections 2, 6, 7
              (with respect to the license), 11-12, 14-19, and 21 shall survive
              any termination or expiration of this Agreement.
            </p>

            <p></p>

            <p>DISPUTE RESOLUTION AND ARBITRATION AGREEMENT</p>

            <p>
              (a) Agreement to Binding Arbitration Between You and LegendsRally.
            </p>

            <p>
              YOU AND LegendsRally MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS
              TO RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND
              AGREE TO RESOLVE ANY DISPUTE BY ARBITRATION, as set forth below.
              This agreement to arbitrate (&ldquo;Arbitration Agreement&rdquo;)
              is governed by the Federal Arbitration Act and survives after the
              Agreement terminates or your relationship with LegendsRally ends.
              ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN
              INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT
              PERMITTED. Except as expressly provided below, this Arbitration
              Agreement applies to all Claims (defined below) between you and
              LegendsRally, including our affiliates, subsidiaries, parents,
              successors and assigns, and each of our respective officers,
              directors, employees, agents, or shareholders.
            </p>

            <p></p>

            <p>
              Except as expressly provided below, ALL DISPUTES AND CLAIMS
              BETWEEN US (EACH A &ldquo;CLAIM&rdquo; AND COLLECTIVELY,
              &ldquo;CLAIMS&rdquo;) SHALL BE EXCLUSIVELY RESOLVED BY BINDING
              ARBITRATION SOLELY BETWEEN YOU AND LegendsRally. These Claims
              include, but are not limited to, any dispute, claim or
              controversy, whether based on past, present, or future events,
              arising out of or relating to: this Agreement and prior versions
              thereof (including the breach, termination, enforcement,
              interpretation or validity thereof), the LegendsRally Platform,
              the Services, any other goods or services made available through
              the LegendsRally Platform, your relationship with LegendsRally,
              the threatened or actual suspension, deactivation or termination
              of your User Account or this Agreement, payments made by you or
              any payments made or allegedly owed to you, any promotions or
              offers made by LegendsRally, any city, county, state or federal
              wage-hour law, trade secrets, unfair competition, compensation,
              breaks and rest periods, expense reimbursement, wrongful
              termination, discrimination, harassment, retaliation, fraud,
              defamation, emotional distress, breach of any express or implied
              contract or covenant, claims arising under federal or state
              consumer protection laws; claims arising under antitrust laws,
              claims arising under the Telephone Consumer Protection Act and
              Fair Credit Reporting Act; and claims arising under the Uniform
              Trade Secrets Act, Civil Rights Act of 1964, Americans With
              Disabilities Act, Age Discrimination in Employment Act, Older
              Workers Benefit Protection Act, Family Medical Leave Act, Fair
              Labor Standards Act, Employee Retirement Income Security Act
              (except for individual claims for employee benefits under any
              benefit plan sponsored by LegendsRally and covered by the Employee
              Retirement Income Security Act of 1974 or funded by insurance),
              and state statutes, if any, addressing the same or similar subject
              matters, and all other federal and state statutory and common law
              claims. All disputes concerning the arbitrability of a Claim
              (including disputes about the scope, applicability,
              enforceability, revocability or validity of the Arbitration
              Agreement) shall be decided by the arbitrator, except as expressly
              provided below.
            </p>

            <p></p>

            <p>
              BY AGREEING TO ARBITRATION, YOU UNDERSTAND THAT YOU AND
              LegendsRally ARE WAIVING THE RIGHT TO SUE IN COURT OR HAVE A JURY
              TRIAL FOR ALL CLAIMS, EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN
              THIS ARBITRATION AGREEMENT. This Arbitration Agreement is intended
              to require arbitration of every claim or dispute that can lawfully
              be arbitrated, except for those claims and disputes which by the
              terms of this Arbitration Agreement are expressly
            </p>

            <p>excluded from the requirement to arbitrate.</p>

            <p></p>

            <p>(b) Optional Pre-Arbitration Negotiation Process.</p>

            <p>
              Before initiating any arbitration or proceeding, you and
              LegendsRally may agree to first attempt to negotiate any dispute,
              claim or controversy between the parties informally for 30 days,
              unless this time period is mutually extended by you and
              LegendsRally. A party who intends to seek negotiation under this
              subsection must first send to the other a written notice of the
              dispute (&ldquo;Notice&rdquo;). The Notice must (1) describe the
              nature and basis of the claim or dispute; and (2) set forth the
              specific relief sought. All offers, promises, conduct and
              statements, whether oral or written, made in the course of the
              negotiation by any of the parties, their agents, employees, and
              attorneys are confidential, privileged and inadmissible for any
              purpose, including as evidence of liability or for impeachment, in
              arbitration or other proceeding involving the parties, provided
              that evidence that is otherwise admissible or discoverable shall
              not be rendered inadmissible or non-discoverable as a result of
              its use in the negotiation.
            </p>

            <p></p>

            <p>Confidentiality</p>

            <p>
              You agree not to use any technical, financial, strategic and other
              proprietary and confidential information relating to
              LegendsRally&rsquo;s business, operations and properties,
              including User information (&ldquo;Confidential
              Information&rdquo;) disclosed to you by LegendsRally for your own
              use or for any purpose other than as contemplated herein. You
              shall not disclose or permit disclosure of any Confidential
              Information to third parties. You agree to take all reasonable
              measures to protect the secrecy of and avoid disclosure or use of
              Confidential Information of LegendsRally in order to prevent it
              from falling into the public domain. Notwithstanding the above,
              you shall not have liability to LegendsRally with regard to any
              Confidential Information which you can prove: was in the public
              domain at the time it was disclosed by LegendsRally or has entered
              the public domain through no fault of yours; was known to you,
              without restriction, at the time of disclosure, as demonstrated by
              files in existence at the time of disclosure; is disclosed with
              the prior written approval of LegendsRally; becomes known to you,
              without restriction, from a source other than LegendsRally without
              breach of this Agreement by you and otherwise not in violation of
              LegendsRally&rsquo;s rights; or is disclosed pursuant to the order
              or requirement of a court, administrative agency, or other
              governmental body; provided, however, that You shall provide
              prompt notice of such court order or requirement to LegendsRally
              to enable LegendsRally to seek a protective order or otherwise
              prevent or restrict such disclosure.
            </p>

            <p>Relationship with LegendsRally</p>

            <p></p>

            <p>
              As a Venue on the LegendsRally Platform, you acknowledge and agree
              that you and LegendsRally are in a direct business relationship,
              and the relationship between the parties under this Agreement is
              solely that of independent contracting parties. You and
              LegendsRally expressly agree expressly agree that (1) this is not
              an employment agreement and does not create an employment
              relationship between you and LegendsRally; and (2) no joint
              venture, franchisor-franchisee, partnership, or agency
              relationship is intended or created by this Agreement. You have no
              authority to bind LegendsRally, and you undertake not to hold
              yourself out as an employee, agent or authorized representative of
              LegendsRally.
            </p>

            <p></p>

            <p>
              LegendsRally does not, and shall not be deemed to, direct or
              control you generally or in your performance under this Agreement
              specifically, including in connection with your provision of
              Services, your acts or omissions, or your operation and
              maintenance of your vehicle. You retain the sole right to
              determine when, where, and for how long you will utilize the
              LegendsRally Platform. You retain the option to accept or to
              decline or ignore a Subscriber&rsquo;s request for Services via
              the LegendsRally Platform, or to cancel an accepted request for
              Services via the LegendsRally Platform, subject to
              LegendsRally&rsquo;s then-current cancellation policies. With the
              exception of any signage required by law or permit/license rules
              or requirements, LegendsRally shall have no right to require you
              to: (a) display LegendsRally&rsquo;s names, logos or colors on
              your vehicle(s); or (b) wear a uniform or any other clothing
              displaying LegendsRally&rsquo;s names, logos or colors. You
              acknowledge and agree that you have complete discretion to provide
              Services or otherwise engage in other business or employment
              activities.
            </p>

            <p>General</p>

            <p></p>

            <p>
              Except as provided in Section 17, this Agreement shall be governed
              by the laws of the State of Texas without regard to choice of law
              principles.
            </p>
          </section>

          <footer>
            <p>
              Please note that these Terms and Conditions are subject to change,
              and any updates will be communicated through our official
              channels.
            </p>
            <p>
              Thank you for using Legends Rally Official. We appreciate your
              understanding and cooperation.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Terms;
