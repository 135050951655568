import React from "react";
import CollectJSSection from "./CollectJSSection";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Puff as Loader } from "react-loader-spinner";
import "../checkout/CheckoutStyles.css";
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const CollectjsForm = (props) => {
  const history = useNavigate();
  const [amount, setAmount] = React.useState(0);
  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    isSubmitting: false,
    alertMessage: "",
  });
  React.useEffect(() => {
    const getAmount = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_URL + "paymentIntent",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              form: props.checkoutForm,
              preRegistrationCode: props.preRegistrationCode,
            }),
          }
        );
        const { clientSecret, amount } = await response.json();
        if (amount) {
          setAmount(amount);
        }
      } catch (e) {}
    };
    getAmount();
  }, []);
  React.useEffect(() => {
    window.CollectJS.configure({
      variant: "inline",
      styleSniffer: false,
      customCss: {
        "min-height": "30px",
        padding: "10px 12px",
        "justify-content": "center",
        "align-items": "center",
        color: "#32325d",
        "background-color": "white",
        border: "1px solid #e6ebf1",
        "border-radius": "4px",
        "box-shadow": "0 1px 3px 0 #e6ebf1",
        transition: "box-shadow 150ms ease",
        "font-size": "18px",
      },
      callback: (token) => {
        finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "CC Number",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "CC Expiration",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "CVV",
          selector: "#cvv",
        },
      },
    });
    return () => {};
  }, []);
  const finishSubmit = async (response) => {
    const { isSubmitting, alertMessage, ...formData } = { ...state };
    const paymentResponse = await fetch(
      process.env.REACT_APP_SERVER_URL + "pay",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          form: props.checkoutForm,
          paymentToken: response.token,
        }),
      }
    );
    const res = await paymentResponse.json();
    console.log({ res });
    setState({
      ...state,
      ...{
        isSubmitting: false,
        alertMessage: res.responsetext,
      },
    });
    if (res.response == "1") {
      if (props.onCheckout) {
        await props.onCheckout();
      }
      history("/checkoutResult");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setState({ ...state, ...{ isSubmitting: true } });
    window.CollectJS.startPaymentRequest();
  };

  return (
    <div className="App">
      {state.alertMessage && <div className="alert">{state.alertMessage}</div>}
      <form
        onSubmit={handleSubmit}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "95%" }}>
          <CollectJSSection />
        </div>
        <button
          type="submit"
          disabled={state.isSubmitting}
          style={{
            margin: 30,
            fontSize: 18,
            height: 65,
            width: 250,
            borderRadius: 10,
            border: "none",
            background: "#188CBE",
            cursor: "pointer",
          }}
        >
          {state.isSubmitting ? (
            <div>
              <Loader color="#00BFFF" height={20} width={20} />
            </div>
          ) : (
            `Confirm ${formatter.format(amount)} Payment`
          )}
        </button>
      </form>
    </div>
  );
};

export default CollectjsForm;
