import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import logo from "./assets/images/LegendsRally.jpeg";
import { Routes, Route, Outlet } from "react-router-dom";
import Home from "./components/pages/Home";
import Checkout from "./components/pages/Checkout";
import CheckoutResult from "./components/pages/CheckoutResult";
import LandingPage from "./components/pages/LandingPage";

export default function App() {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        backgroundColor: "#FF4D22",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          backgroundColor: "white",
          width: 205,
          height: 205,
          borderRadius: 205,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src={"/LegendsLogo.png"}
          alt="Logo"
          style={{ width: 200, objectFit: "contain" }}
        ></img>
      </div>
      <br />
      <div>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Outlet />
      </div>
      <br />
      <img
        src={logo}
        alt="Logo"
        style={{ width: "100%", objectFit: "contain" }}
      ></img>
      <br />
    </div>
  );
}
