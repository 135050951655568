import React from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import CollectjsForm from "../checkout/CollectjsForm";
import FirebaseHoc from "../../hoc/FirebaseHoc";
const Checkout = (props) => {
  const location = useLocation();
  let buyerDetails = [];
  if (location && location.state && location.state.formState) {
    buyerDetails = Object.keys(location.state.formState).map((key) => {
      if (key == "Discount" && !location.state.formState[key]) {
        return null;
      }
      if (key == "Image") {
        if (location.state.formState[key]) {
          return (
            <div
              key={key}
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: 5,
              }}
            >
              {key + ": "}
              <img
                src={URL.createObjectURL(location.state.formState[key])}
                width="100px"
                height="100px"
              />
            </div>
          );
        } else {
          return null;
        }
      }
      if (key == "FirstName") {
        return (
          <div key={key}>{"First Name: " + location.state.formState[key]}</div>
        );
      }
      if (key == "LastName") {
        return (
          <div key={key}>{"Last Name: " + location.state.formState[key]}</div>
        );
      }
      return <div key={key}>{key + ": " + location.state.formState[key]}</div>;
    });
    if (location.state.preRegistrationCode) {
      buyerDetails.push(
        <div key={"preRegistrationCode"}>
          {"Pre-Registration Code : " + location.state.preRegistrationCode}
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <div
          className="card"
          style={{
            display: "flex",
            flex: 0.9,
            flexDirection: "column",

            borderRadius: 25,
            backgroundColor: "white",
            opacity: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2>Ticket Registration Details</h2>
            <div style={{ alignItems: "flex-start" }}>{buyerDetails}</div>
          </div>
          <FirebaseHoc checkoutForm={location.state.formState} />
        </div>
      </div>
    );
  }
  return <Navigate to="/" />;
};
export default Checkout;
