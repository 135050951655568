import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Checkout from "./components/pages/Checkout";
import CheckoutResult from "./components/pages/CheckoutResult";
import Home from "./components/pages/Home";
import Terms from "./components/pages/Terms";
import Ticketpolicy from "./components/pages/ReturnPolicy";
import Liability from "./components/pages/Liability";
import reportWebVitals from "./reportWebVitals";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      { path: "checkout", element: <Checkout /> },
      { path: "checkoutResult", element: <CheckoutResult /> },
      { path: "/home", element: <Home /> },
      { path: "/termsofservice", element: <Terms /> },
      { path: "/refundpolicy", element: <Ticketpolicy /> },
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      { path: "/liabilitywaiver", element: <Liability /> },
      { path: "/", element: <Home /> },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
