import React from "react";

function PrivacyPolicy() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div
        className="card"
        style={{
          display: "flex",
          flex: 0.9,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 25,
          backgroundColor: "white",
          opacity: 1,
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          <header style={{ textAlign: "center" }}>
            <h1>PrivacyPolicy for Legends Rally Official</h1>
          </header>
          <section>
            <p>
              IMPORTANT: THIS IS A BINDING LEGAL AGREEMENT. READ IT CAREFULLY IN
              ITS ENTIRETY. USE OF THE Maclevin Ventures LLC (&ldquo;Legends
              Rally&rdquo;) APPLICATION, FIRMWARE, MOBILE APPLICATION, PLATFORM,
              AND ANY ACCOMPANYING WRITTEN APPLICATION OR ELECTRONIC
              DOCUMENTATION (COLLECTIVELY, THE &ldquo;APPLICATION&rdquo;)
              PROVIDED OR ASSOCIATES WITH THIS END LICENSEE LICENSE AGREEMENT
              (THIS &ldquo;AGREEMENT&rdquo;) CONSTITUTES YOUR UNEQUIVOCAL
              ACCEPTANCE OF THE TERMS OF THIS AGREEMENT.
            </p>

            <p>
              AFTER YOU HAVE READ THIS AGREEMENT, YOU MUST CHOOSE TO EITHER
              ACCEPT IT OR CANCEL WITHOUT ACCEPTING. IF YOU ARE ACCESSING THE
              APPLICATION ELECTRONICALLY, SIGNIFY YOUR AGREEMENT TO BE BOUND BY
              THE TERMS OF THIS AGREEMENT BY CLICKING THE
              &ldquo;AGREE/ACCEPT&rdquo; BUTTON OR DOWNLOADING OR USING THE PARK
              UP FRONT MOBILE APPLICATION ACCOMPANYING THIS LICENSE. IF YOU DO
              NOT AGREE TO THE TERMS OF THIS LICENSE AS PROVIDED FOR IN THIS
              AGREEMENT, DO NOT USE OR DOWNLOAD THE APPLICATION AND DO NOT CLICK
              THE &ldquo;ACCEPT&rdquo; BUTTON.
            </p>

            <p>GENERAL</p>

            <p></p>

            <p>
              This End-Licensee License Agreement (&quot;EULA&quot;) is a legal
              agreement between Licensee (either as an individual or a business
              entity as discussed below) and Maclevin Ventures LLC
              (&quot;Legends Rally&quot;), a Texas Limited Liability Company,
              for Licensee&rsquo;s use of the Application.
            </p>

            <p></p>

            <p>
              This EULA concerns Legends Rally&rsquo;s willingness to license,
              not sell, the Legends Rally Mobile Application and Application to
              Licensee only upon the condition that Licensee accepts all the
              terms contained in this Agreement and any &ldquo;usage
              rules&rdquo; established by any other third party usage rules or
              terms of use, such as Apple, Inc. and such other vendors
              (&ldquo;Usage Rules&rdquo;), which are incorporated herein by this
              reference and Legends Rally&rsquo;s Terms of Use, an updated copy
              which may be found at www.legendsrallyofficial.com. By signing up
              with or using the Legends Rally Platform, Licensee indicates that
              Licensee understands this Agreement and accept all of its terms.
            </p>

            <p>1. DEFINITIONS</p>

            <p> The following definitions apply to this EULA.</p>

            <p>&#8203;</p>

            <p>
              1.1 &quot;Agreement&quot; means collectively (i) this End Licensee
              License Agreement, and (ii) the Terms and Conditions.
            </p>

            <p>&#8203;</p>

            <p>
              1.2 &quot;Effective Date&quot; means the date the Parties enter
              into this Agreement.
            </p>

            <p>&#8203;</p>

            <p>
              1.3 &quot;EULA&quot; means this End Licensee License Agreement.
            </p>

            <p>&#8203;</p>

            <p>
              1.4 &quot;Licensee&quot; means either the individual or business
              that is downloading, updating, using or accessing the Application
              or, if the person downloading, updating or accessing the
              Application is an agent, employee, or other representative
              authorized to bind a company or other legal entity to this EULA.
            </p>

            <p>&#8203;</p>

            <p>
              1.5 &quot;Hybrid&quot; means the implementation of both web-based
              Application and client-server based Application which will share a
              common Legends Rally database.
            </p>

            <p></p>

            <p>
              1.6 &quot;Intellectual Property&quot; means any patents, logos,
              know-how, proprietary processes, proprietary information and other
              intangible property rights encompassed in or related to the
              Application and any content.
            </p>

            <p></p>

            <p>
              1.7 &quot;Application&quot; means the mobile software accompanying
              this License, including, without limitation (i) any binary
              executable computer programs and any associated files, structure
              sequence and organization, input and output protocols, screens,
              functionality, software code, interfaces, graphics , associated
              media, printed materials, scripts, displays, text, artwork and
              &quot;online&quot; or electronic documentation contained,
              downloaded, accessed, or otherwise made available to Licensee
              pursuant to this EULA, now or in the future; (ii) any updates,
              modifications, or enhancements to the items listed in subsection
              (i); and (iii) any specific website the Application directs you to
              via any browser location on an iPhone or Samsung or such other
              mobile device (&ldquo;Mobile Device&rdquo;).
            </p>

            <p>&#8203;</p>

            <p>
              1.8 &quot;Updates &amp; Upgrades&quot; means additional
              Application modules Legends Rally, in its sole discretion, may
              provide to Licensee for use by Licensee for which Licensee has
              paid the applicable license fees, if any, and accepted any
              applicable additional license terms.
            </p>

            <p>&#8203;</p>

            <p>
              1.9 &ldquo;Services&rdquo; The Legends Rally Mobile Application
              provide a means to enable persons who seek to park their luxury
              vehicle in a specified spot at certain destinations to be matched
              with a valet at those destinations. For purposes of this Agreement
              these services shall collectively be defined as the
              &ldquo;Services.&rdquo; The Terms of Service or Usage Rules will
              further govern Licensee&rsquo;s use of and participation in the
              Legends Rally Mobile Application.
            </p>

            <p>&#8203;</p>

            <p>2. LICENSE GRANT</p>

            <p>&#8203;</p>

            <p>
              2.1 License Grant. Subject to the terms and conditions of this
              Agreement, Legends Rally grants Licensee a nontransferable,
              nonexclusive, royalty-free worldwide license to access and use the
              Application strictly in accordance with the terms and conditions
              of this License, the Usage Rules and any service agreement
              associated with your Mobile Device (collectively &ldquo;Related
              Agreements&rdquo;).
            </p>

            <p>
              Licensee acknowledges that it has no rights to distribute or
              otherwise disclose, display, publicly perform or transfer the
              Application to any third party via any means (including without
              limitation by electronic, magnetic, wireless, tape relay, or other
              means, or via loan, lease or sublicense of any nature), or to
              modify or create derivative works of the Application.
            </p>

            <p>&#8203;</p>

            <p>
              2.2 Restrictions on Use. Licensee shall use the Application
              strictly in accordance with the terms of the Related Agreement and
              shall not: (a) decompile, reverse engineer, disassemble, attempt
              to derive the source code of, or decrypt the Application; (b) make
              any modification, adaptation, improvement or enhancement,
              translation or derivative work from the Application; (c) violate
              any applicable laws, rules or regulations in connection with
              Licensee&rsquo;s access or use of the Application (d) remove,
              alter or obscure any proprietary notice (including any notice of
              copyright or trademark of Legends Rally or its affiliates,
              partners, suppliers, or the licensors of the Application; (e) use
              the Application for any revenue generating endeavor aside from
              those listed in Related Agreements, commercial enterprise, or
              other purpose for which it is not designed or intended; (f)
              distribute the Application to multiple Mobile Devices; (g) make
              the Application available over a network or other environment
              permitting access or use by multiple Mobile Devices or users at
              the same time; (h) use the Application for creating a product,
              service or software that is, directly or indirectly, competitive
              with or in any way a substitute for any services, produce or
              software offered by Legends Rally; (i) use the Application to send
              automated queries to any website or to send any unsolicited
              commercial e-mail; or (j) use any proprietary information or
              interfaces of Legends Rally or other intellectual property of
              Legends Rally in the design, development, manufacture, licensing
              or distribution of any applications, accessories, or devises for
              use with the Application.
            </p>

            <p>&#8203;</p>

            <p>
              2.3 Access to Update Application. Legends Rally will provide
              Licensee, under the terms of this Agreement, with access to all
              updates, if any, to the Application as Legends Rally may generally
              make; when such updates are available. Application updates may
              include minor additions, enhancements, or fixes to the
              Application. Updates do not include upgrades to newer versions of
              the Application.
            </p>

            <p>&#8203;</p>

            <p>
              2.4 Eligibility. Legends Rally&rsquo;s Services are available only
              to, and may only be used by individuals who can form legally
              binding contracts under applicable law. Without limiting the
              foregoing, Legends Rally&rsquo;s Services are not available to
              children (persons under the age of 18) or to temporarily to
              indefinitely terminated Licensees. By becoming a Licensee, You
              represent and warrant that You are at least 18 years old. By using
              the Application or Services, You represent and warrant that You
              have the right, authority, and capacity to enter into this EULA
              and to abide by the terms and conditions of this Agreement.
            </p>

            <p>&#8203;</p>

            <p>
              Licensee acknowledges and agrees that Licensee is the sole
              authorized user of licensee&rsquo;s account. Licensee is
              responsible for maintaining the confidentiality of any password
              provided by You or Legends Rally for accessing the Application and
              Services. Licensee is solely and fully responsible for all
              activities that occur under Licensee&rsquo;s account. Legends
              Rally has no control over the use of Licensee&rsquo;s account and
              expressly disclaims any liability derived therefrom.
            </p>

            <p>3. INTELLECTUAL PROPERTY RIGHTS</p>

            <p>&#8203;</p>

            <p>
              3.1 Rights to Application. Licensee further acknowledges and
              agrees that the Application and all copyrights, patents,
              trademarks, trade secrets, and other intellectual property and
              proprietary and confidential information associated therewith are
              and shall remain the property of Legends Rally. Licensee
              acknowledges and agrees that the source and object code of the
              Application and the format, directories, queries, algorithms,
              structure and organization of the Application are the intellectual
              property and proprietary information of Legends Rally and its
              affiliates, licensors and suppliers. All rights not granted to
              Licensee in the Application shall remain with and are expressly
              reserved by Legends Rally.
            </p>

            <p>&#8203;</p>

            <p>
              3.2 Third Party Software. The Application may utilize or include
              third party Software that is subject to open source and third
              party license terms (&ldquo;Third Party Software&rdquo;). Licensee
              acknowledges and agrees that Licensee&rsquo;s right to use such
              Third Party Software as part of the Application is subject to and
              governed by the terms and conditions of the open source or third
              party license applicable to such Third Party Software, including,
              without limitation, any applicable acknowledgements, license terms
              and disclaimers contained therein. In the event of a conflict
              between the terms of this License and the terms of such open
              source or third party licenses, the terms of the open source or
              third party licenses shall control with regard to Licensee&rsquo;s
              use of relevant Third Party Software. In no event, shall the
              Application or components thereof be deemed to be &ldquo;open
              source&rdquo; or &ldquo;publicly available&rdquo; software.
            </p>

            <p>&#8203;</p>

            <p>
              3.3 Company Logo and Merchandizing. Licensee is not authorized to
              use Legends Rally&rsquo;s trademarks in any advertising, publicity
              or in any other commercial manner without the prior written
              consent of the Company, which may be withheld for any or no
              reason.
            </p>

            <p>&#8203;</p>

            <p>
              3.4 Infringement Acknowledgement. Licensee and Legends Rally
              acknowledge and agree that, in the event of a third party claim
              that the Application or Licensee&rsquo;s use of the Application
              infringes on any third party&rsquo;s intellectual property rights,
              Licensee (and not Legends Rally) will be responsible for the
              investigation, defense, settlement, and discharge of any such
              claim of intellectual property infringement. Licensee, will,
              however, promptly notify Legends Rally in writing of such claim.
            </p>

            <p>&#8203;</p>

            <p>
              3.5 Restriction on Transfer. Licensee may not rent, lease, land,
              sublicense, or transfer the Application, this License, or any of
              the rights granted hereunder. Any attempted transfer in
              contravention of this provision shall be null and void and of no
              force or effect.
            </p>

            <p>&#8203;</p>

            <p>4. USE OF INFORMATION</p>

            <p>&#8203;</p>

            <p>
              4.1 Consent to Use Information. Licensee hereby authorizes and
              consents to the collection, storage and use, by Legends Rally and
              its affiliates, partners and agents, of any information and data
              related to or derived from Licensee&rsquo;s use of the
              Application, and any information or data that Licensee provides to
              Legends Rally and its affiliates, partners, and licensors
              (&ldquo;Information&rdquo;). Without limiting the generality of
              the foregoing, the Information shall include, without limitation,
              the following types of information and dates, in an aggregate (not
              user level) form: search requests, search results, patterns, data
              and suggestions based on user actions. Notwithstanding the
              foregoing, Licensee shall not provide or disclose and the
              Information shall not include and information or data that is
              personally identifiable to Licensee. The Information will be
              treated as being non-confidential and nonproprietary, and Legends
              Rally assumes no obligation to protect confidential or proprietary
              information (other than personally identifiable information) from
              disclosure and will be free to reproduce, use and distribute the
              Information to others without restriction. Legends Rally will also
              be free to use any ideas, concepts, know-how or techniques
              contained in the Information for any purpose whatsoever,
              including, without limitation, developing, manufacturing, and
              marketing products and services incorporating such information.
              Licensee warrants that any information provided or uploaded as a
              result of Licensee&rsquo;s use of the Application is owned wholly
              by the Licensee and Licensee agrees the Licensee is prohibited
              from using the Application in any manner that infringes on the
              ownership rights of any third-party.
            </p>

            <p>&#8203;</p>

            <p>
              4.2 Privacy Policy. Licensee represents that Licensee shall comply
              with the terms and conditions of the Legends Rally Privacy Policy,
              which sets forth and describes the practices of Legends Rally with
              respect to the collection, use and disclosure of Information in
              connection with Licensee&rsquo;s use of the Application. Legends
              Rally reserves the right to change the provisions of its Privacy
              Policy at any time and from time to time at its sole discretion.
              Legends Rally will post any changes to its Privacy Policy its web
              address. Licensee&rsquo;s use of the Application following the
              posting of such changes to the Privacy Policy will constitute
              Licensee&rsquo;s acceptance of any such changes.
            </p>

            <p>&#8203;</p>

            <p>5. THIRD PARTY CONTENT AND SERVICES.</p>

            <p>&#8203;</p>

            <p>
              5.1 General. Licensee acknowledges that the Application permits
              access to products, services, web-sties, advertisements,
              promotions, recommendations, advice, information, and materials
              created and provided by advertisers, publishers, content partners,
              marketing agents, vendors and other third parties (&ldquo;Third
              Party Content and Services&rdquo;).
            </p>

            <p>&#8203;</p>

            <p>
              5.2 Disclaimer. Licensee acknowledges that Legends Rally does not
              investigate, monitor, represent or endorse the Third Party Content
              and Services (including any third party websites available through
              the Application). Furthermore, Licensee&rsquo;s access to and use
              of the Third Party Content and Services is at Licensee&rsquo;s
              sole discretion and risk, and Legends Rally and its affiliates,
              partners, suppliers, and licensors shall have no liability to
              Licensee arising our of or in connection with Licensee&rsquo;s
              access to and use of the Third Party Content and Services, whether
              express, implied or statutory , including, without limitation, the
              implied warranties of merchantability or fitness for a particular
              purpose, and any representation warrant or guaranty regarding the
              availability, quality reliability, features, appropriates,
              accuracy, completeness, or legality of the Third Party Content or
              Services.
            </p>

            <p>&#8203;</p>

            <p>
              5.3 Third Party Terms of Service. Licensee acknowledges and agrees
              that Licensee&rsquo;s access to and use of the Third Party Content
              and Services and any correspondence or business dealing b between
              Licensee and any third party located using the Application are
              governed by and require Licensee&rsquo;s acceptance of the terms
              of service of such third party, including, without limitation, any
              terms, privacy policies, conditions, representations, warranties
              or disclaimers contained therein. Furthermore, Licensee
              acknowledges and agrees that the Third Party Content and Services
              and any related third party terms of service are subject to change
              by the applicable third party at its sole discretion and without
              any notice. Licensee assumes all risks arising out of or resulting
              from your transaction of business over the Internet and with any
              third party, and Licensee agrees that Legends Rally, its
              affiliates, partners, suppliers, and licensors are not responsible
              or liable for any loss or result of the presence of information
              about or links to such advertisers or service providers. Licensee
              acknowledges that Licensee is not being granted a license to (i)
              the Third Party Content and Services; (ii) any products, service,
              processes or technology described in or offered by the Third Party
              Content and Services; or (iii) any copyrights, trademark, patent,
              or other intellectual property right in the Third Party Content or
              Services described or offered therein.
            </p>

            <p>&#8203;</p>

            <p>
              5.1 Limited Warranty. THE WARRANTIES GIVE LICENSEE SPECIFIC LEGAL
              RIGHTS. LICENSEE MAY HAVE OTHER LEGAL RIGHTS THAT VARY BY STATE.
              THESE ARE THE ONLY WARRANTIES MADE BY Legends Rally. NO DEALER,
              AGENT, OR EMPLOYEE IS AUTHORIZED TO MAKE ANY MODIFICATIONS,
              EXTENSIONS, OR ADDITIONS TO Legends Rally'S WARRANTIES. SOME
              STATES MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO APPLICATION OF THE LIMITATIONS OR
              EXCLUSIONS HEREIN MAY BE LIMITED BY STATE LAW.
            </p>

            <p>&#8203;</p>

            <p>
              5.2 License Warranty. Legends Rally warrants that Legends Rally is
              the proper party to grant the Application license set forth
              herein, that Legends Rally has not granted licenses in conflict
              herewith, and that Legends Rally has no knowledge that the
              Application infringes any third party's rights. Legends Rally
              otherwise expressly disclaims any promise, warranty or
              representation concerning the validity, scope, enforceability,
              usefulness, value of any right licensed hereunder; that anything
              made, used or sold hereunder will not infringe third parties'
              rights; or any obligation of Legends Rally to bring suits against
              third parties for any matter whatsoever. Licensee is not promised
              and does not expect any specific or equal benefit from any of the
              grants, promises or licenses herein other than a license to access
              to the Application under the terms herein. Other Licensees may
              obtain different, better or worse terms, opportunities or results.
              Licensee will promptly notify Legends Rally in writing of any
              Product defects or warranty claims.
            </p>

            <p>&#8203;</p>

            <p>
              5.3 Endorsements. Licensee acknowledges and agrees that the
              provision of access to any Third Party Content and Service shall
              not constitute or imply any endorsement by Legends Rally or its
              affiliates of such Third Party Content and Services. Legends Rally
              reserves the right to restrict or deny access to any Third Party
              Content and Services otherwise accessible through the Application,
              although Legends Rally has no obligation to restrict or deny
              access even if requested by You.
            </p>

            <p>&#8203;</p>

            <p>
              5.4 Inappropriate Materials. Licensee understands that by
              accessing and using the Third Party Content and Services, Licensee
              may encounter information, material and subject matter (i) that
              Licensee or others may deem offensive, indecent, or objectionable;
              (ii) which may or may not be identified as having explicative
              language, and (iii) that automatically and unintentionally appears
              in search results, as a link or reference to objectionable
              material. Notwithstanding the foregoing, Licensee agrees to use
              the Third Party Content and Services and Licensee&rsquo;s own risk
              and that Legends Rally and its affiliates, partners, suppliers,
              and licensors shall have no liability to Licensee for information,
              material or subject matter that is found to be offensive.
            </p>

            <p>&#8203;</p>

            <p>
              5.5 Use of Third Party Content and Services. Licensee agrees that
              the Third Party Content and Services contain proprietary
              information and material that is owned by Legends Rally and its
              affiliates, partners, suppliers, and licensors and is protected by
              applicable intellectual property and other laws, including,
              without limitation, pursuant to copyright, and that Licensee will
              not use such proprietary information or other materials in any way
              whatsoever except for permitted use of the Third Party Content and
              Services. No potion of the Third Party Content and Services may be
              reproduced in any form or by any means. Licensee agrees not to
              modify, rent, lease, loan, sell, distribute, or create derivative
              works based on the Third Party Content and Services, in any
              manner, and Licensee shall not exploit the Third Party Content and
              Services in any unauthorized way whatsoever, including, without
              limitation, by trespass or burdening network capacity. Licensee
              agrees that Licensee will not use any Third Party Content and
              Services in a manner that would infringe or violate the rights of
              any other party, and that Legends Rally is not in any way
              responsible for such use by Licensee.
            </p>

            <p>6. TERM AND TERMINATION.</p>

            <p>&#8203;</p>

            <p>6.1 Term. This License shall be effective until terminated.</p>

            <p>&#8203;</p>

            <p>
              6.2 Termination. Licensee may terminate Licensee&rsquo;s use or
              participation of Legends Rally&rsquo;s Application or Services at
              anytime, for any reason upon receipt by Legends Rally of
              Licensee&rsquo;s written notice of termination. Legends Rally may,
              in its sole discretion and absolute discretion, at any time and
              for any or no reason, suspend or terminate this License and the
              rights afforded to You hereunder with or without prior notice.
              Furthermore, if Licensee fails to comply with any terms and
              conditions of this License, then this License and any rights
              afforded to Licensee hereunder shall terminate automatically,
              without any notice or other action by Legends Rally. Upon the
              termination of this License, You shall cease all use of the
              Application and uninstall the Application. Legends Rally maintains
              sole discretion to bar Licensee&rsquo;s use of the Services in the
              future, for any or no reason.
            </p>

            <p>&#8203;</p>

            <p>
              7. Warranty Disclaimer. EXCEPT AS EXPRESSLY STATED HEREIN, Legends
              Rally EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES OR
              CONDITIONS OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, ACCURACY OF ANY INFORMATIONAL CONTENT OR THOSE
              ARISING BY STATUTE, OF CONFORMITY TO ANY REPRESENTATIONS OR
              DESCRIPTIONS NOT CONTAINED HEREIN, OR OTHERWISE IN LAW OR FROM
              COURSE OF DEALING OR USAGE OF TRADE. WITHOUT LIMITING THE
              FOREGOING, Legends Rally DOES NOT WARRANT THAT ANYTHING WILL MEET
              LICENSEE'S REQUIREMENTS, WILL BE UNINTERRUPTED, ACCURATE,
              RELIABLE, TIMELY, SERCURE, OR ERROR-FREE. YOU ACKNOWLEDGE AND
              AGREE THAT THE APPLICATION IS PROVIDED ON AN &ldquo;AS IS &ldquo;
              BASIS AND &ldquo;AS AVAILABLE&rdquo; BASIS, AND THAT YOUR USE OF
              OR RELIANCE UPON THE APPLCIATION AND ANY THIRD PARTY CONTENT AND
              SERVICES ACCESSED THEREBY IS AT YOU SOLE RISK AND DISCRETION.
              Legends Rally'S OBLIGATIONS, IF ANY, ARE CONDITIONAL ON PURCHASER
              PROMPTLY COMPLYING WITH ALL OF THIS EULA'S TERMS AND CONDITIONS.
              LICENSEE ACCEPTS THE APPLICATION &quot;AS IS&quot; AND WITH ALL
              FAULTS. THE LIMITED WARRANTIES AND REMEDIES IN THIS AGREEMENT ARE
              THE SOLE AND EXCLUSIVE WARRANTIES AND REMEDIES CONCERNING ANY
              GOODS, SERVICES, OR INTANGIBLES, NOW OR IN THE FUTURE. NO ADVICE
              OR INFROMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
              Legends Rally OR FROM THE APPLICATION SHALL CREATE ANY
              REPRESENTATIO, WARRANTY OR GUARANTY, FURTHERMORE, YOU ACKNOWLEDGE
              THAT Legends Rally HAS NO OBLIGATI ON TO CORRECT ANY ERRORS OR
              THERWISE SUPPORT OT MAINTAIN THE APPLICATION.
            </p>

            <p>&#8203;</p>

            <p>
              8. Limitation of Liability. IN NO EVENT SHALL Legends Rally BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, SPECIAL
              OR CONSEQUENTIAL DAMAGES OR REMEDIES RELATING TO THIS EULA, THE
              APPLICATION OR ANY Legends Rally PRODUCTS, GOODS, SERVICES OR
              INTANGIBLES (EXCLUDED DAMAGES INCLUDE, WITHOUT LIMITATION, FOR
              LOST PROFITS, BUSINESS INTERRUPTION, COSTS OF DELAY, FAILURE OF
              DELIVERY, REVENUE, GOODWILL, LOST OR DAMAGED DATA, DOCUMENTATION
              OR EQUIPMENT, LOSS OF BUSINESS INFORMATION, COST OF REMOVAL OR
              INSTALLATION OF ANYTHING, INTERCEPTIONS, DEFECTS, VIRUSES, DELAYS,
              OR FAILURE OF PERFORMANCE, OTHER LOSS ARISING OUT OF USE, OR
              INABILITY TO USE THE PRODUCT, LIABILITIES TO THIRD PARTIES,
              INABILITY TO USE THE APPLICATION, ERRORS IN THE APPLICATION,
              MALFUNCTIONS OR ERRONEOUS DATA, PAYMENTS TO THIRD PARTIES WHICH
              ARE TOO SMALL, TOO LARGE, TOO LATE OR ARE OTHERWISE IMPROPER),
              EVEN IF Legends Rally HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES AND REGARDLESS OF THE FORM OF ACTION (INCLUDING, WITHOUT
              LIMITATION, CONTRACT, NEGLIGENCE, TORT, WARRANTY, ETC.), ANY
              ASSERTED Legends Rally BREACH OF PROMISE OR WARRANTY; ANY ACT OR
              FAILURE TO ACT; NEGLIGENCE INCLUDING GROSS NEGLIGENCE; OR ANY
              CLAIM MADE AGAINST LICENSEE BY ANY OTHER PARTY. WITHOUT LIMITING
              THE FOREGOING, IN NO EVENT SHALL Legends Rally'S LIABILITY (FOR
              ALL CAUSES OF ACTION), EXCEED THE AMOUNT PAID BY LICENSEE TO
              Legends Rally FOR THE SPECIFIC PRODUCT WHICH CAUSED THE PROBLEM.
              THESE LIMITATIONS ARE INDEPENDENT AND APPLY REGARDLESS OF THE
              BASIS OF THE CLAIM, INCLUDING, BUT NOT LIMITED TO, A FINDING THAT
              A WARRANTY, CONDITION, OR REMEDY HAS FAILED ITS ESSENTIAL PURPOSE,
              BREACH OF CONTRACT (INCLUDING, BUT NOT LIMITED TO, FUNDAMENTAL
              BREACH), TORT, (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR
              MISREPRESENTATION), BREACH OF STATUTORY DUTY, OR OTHER LEGAL OR
              EQUITABLE THEORY. ANY CAUSE OF ACTION LICENSEE MAY HAVE AGAINST
              Legends Rally, ITS AFFILIATES, OFFICERS AND AGENTS MUST BE
              COMMENCED WITHIN ONE YEAR AFTER THE CLAIM OR CAUSE OF ACTION
              ARISES OR SHALL BE FOREVER BARRED. Legends Rally'S MAXIMUM
              AGGREGATE LIABILITY SHALL NEVER EXCEED THE AMOUNT PAID BY LICENSEE
              FOR THE PRODUCTS. THIS LIMITATION OF LIABILITY SHALL APPLY
              REGARDLESS OF THE BASIS OF THE CLAIM.
            </p>

            <p>&#8203;</p>

            <p>
              9. Release and Indemnity. Without in any way expanding upon the
              warranty stated above: Legends Rally is not responsible for
              problems caused by changes in the operating characteristics the
              Application; nor is Legends Rally responsible for any problems
              that may arise as a result of anyone improperly downloading,
              installing or using the Application. Licensee shall indemnify,
              defend, and hold harmless Legends Rally and its affiliates,
              partners, suppliers, and licensors, and each of their respective
              officers, directors, agents, and employees (the &ldquo;indemnified
              Parties&rdquo;) from and against any claim, proceeding, loss,
              damage, fine, penalty, interest and expense (including, without
              limitation, fees for attorneys and other professional advisors)
              arising out of or in connection with the following: (i)
              Licensee&rsquo;s access to or use of this Application or Third
              Party Content and Services; (ii) Licensee&rsquo;s breach of this
              License; (ii) Licensee&rsquo;s violation of law; (iv)
              Licensee&rsquo;s negligence or willful misconduct; or (v)
              Licensee&rsquo;s violation of the rights of a third party,
              including the infringement by Licensee and of any intellectual
              property or misappropriation of any proprietary rights or trade
              secrets of any person or entity. These obligations will survive
              any termination of the License. Any unauthorized changes made to
              the Application release Legends Rally from Legends Rally's
              obligations and terminate the EULA. LICENSEE WILL INDEMNIFY, HOLD
              HARMLESS, AND DEFEND Legends Rally AGAINST ANY CLAIM, DEMAND,
              LOSS, OR ACTION RESULTING FROM LICENSEE'S ACTS, FAILURES TO ACT,
              OR LICENSEE'S POSSESSION OR USE OF THE APPLICATION.
            </p>

            <p>&#8203;</p>

            <p>
              10. Compatibility. Legends Rally does not warrant that the
              Application will be compatible or interoperable with your Mobile
              Device or any other piece of hardware, software, equipment, or
              device installed on or used in connection with you Mobile Device.
              Furthermore, Licensee acknowledges that the compatibility and
              interoperability problems can causes the performance of your
              Mobile Device to diminish or fail completely and may result in
              permanent damage to your Mobile Device, loss of date located on
              your Mobile Device, and corruption of the software and files
              located on Your Mobile Device. Licensee acknowledges and agrees
              that Legends Rally and its affiliates, partners, suppliers, and
              Licensors shall have no liability to Licensee for any losses
              suffered resulting from or arising in connection with
              compatibility or interoperability problems.
            </p>

            <p>&#8203;</p>

            <p>
              11. Claims. Licensee acknowledges that Licensee is responsible for
              addressing any kind of third party claims relating to
              Licensee&rsquo;s use or possession of the Application, and agrees
              to notify Legends Rally of any third party claims relating to the
              Application of which Licensee becomes aware. Furthermore, Licensee
              hereby releases Legends Rally from any liability resulting from
              Licensee&rsquo;s use or possession of the Application, including,
              without limitation, the following: (i) any product liability
              claims; (ii) any claim that the Application fails to conform to
              any applicable legal or regulatory requirement; (iii) and, any
              claim arising under consumer protection or similar legislation.
            </p>

            <p>&#8203;</p>

            <p>12. MISCELLANEOUS</p>

            <p>&#8203;</p>

            <p>
              12.1 Ownership of Application. The Application is licensed to
              Licensee, not sold. Licensee owns only the physical media on which
              the Application is recorded or fixed, not the Application. All
              right, title and interest in and to the Application, including any
              permitted copies and all copyrights, trade secrets, trademark,
              patent and other forms of proprietary and intellectual property
              rights, belong only to Legends Rally. This EULA does not convey to
              Licensee any interest in or to the Application, but only a limited
              right of use, revocable in accordance with the terms of this EULA.
              All rights not expressly granted in this EULA are reserved by
              Legends Rally. All title and copyrights in and to the Application,
              Product, related materials and copies thereof are always only
              owned by Legends Rally. All rights not specifically granted to
              Licensee under this EULA are reserved by Legends Rally.
            </p>

            <p>&#8203;</p>

            <p>
              12.2 Waivers. Except as provided herein, the failure to exercise a
              right or require performance of an obligation under this License
              shall not effect a party&rsquo;s ability to exercise such right or
              require such performance at any time thereafter nor shall the
              waiver of a breach constitute waiver of any subsequent breach.
            </p>

            <p>&#8203;</p>

            <p>
              12.3 Severability. If any provision of this License is held to be
              invalid or unenforceable with respect to a party, the remainder of
              this License, or the application of such provision to persons
              other than those to whom it is held invalid or unenforceable shall
              not be affected and each remaining provision of this License shall
              be valid and enforceable to the fullest extent permitted by law.
            </p>

            <p>&#8203;</p>

            <p>
              12.4 Export Control. Licensee may not use or otherwise export or
              re-export the Application except as authorized by United States
              law and the laws of the jurisdiction(s) in which the Application
              was obtained. Licensee represents and warrants that Licensee is no
              located in any country that is subject to a U.S. government
              embargo, or that has been designated by the U.S. Government as a
              &ldquo;terrorist supporting&rdquo; country, or listed on any U.S.
              Government list of prohibited or restricted parties including the
              Treasury Department&rsquo;s list of Specially Designated Nationals
              or the U.S. Department of Commerce Denied Person&rsquo;s List or
              Entity List. Licensee also agrees that Licensee will not use the
              Application for any purposes prohibited by United States law.
            </p>

            <p>&#8203;</p>

            <p>
              12.5 Modification or Amendment. Legends Rally reserves the right,
              in its sole discretion, to amend this EULA from time to time. If
              there is a conflict between this EULA and the most current version
              of this EULA, the most current EULA which may be posted on the
              Company Application EULA website, will prevail. Licensee accepts
              this EULA in its electronic format each time Licensee opens or
              executes Legends Rally's Application. Legends Rally may change the
              EULA from time to time, including, without limitation, material
              changes and changes to the parties' rights and obligations.
              Licensee will be deemed to have agreed to any such modification or
              amendment by Licensee&rsquo;s decision to continue using the
              Application following the date in which the modified or amended
              License is posted on the Company Application EULA website.
            </p>

            <p>&#8203;</p>

            <p>
              12.6 Governing Law: NOTWITHSTANDING THE LOCATION OF USE OF THE
              APPLICATION, THIS AGREEMENT AND USE OF THE APPLICATION IS SOLELY
              GOVERNED BY THE LAWS OF THE STATE OF TEXAS EXCLUDING ITS CONFLICTS
              OF LAW PRINCIPLES. EACH OF THE PARTIES HERETO IRREVOCABLY AGREES
              TO EXCLUSIVE JURISDICTION BY THE COURTS LOCATED IN DALLAS COUNTY,
              TEXAS. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE
              INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM THIS
              AGREEMENT.
            </p>

            <p>&#8203;</p>

            <p>
              12.7 Survival. The following section of this License and any other
              provisions of this License which by their express language or by
              their context are intended to survive the termination of this
              License and shall survive such termination: 1, 2.2, 3, 4, 5, 7, 8
              , 9, 10, and 12.
            </p>

            <p>&#8203;</p>

            <p>
              12.8 Assignment. Except as permitted herein, Licensee shall not
              assign this License or any rights or obligations herein without
              the prior written consent of Legends Rally and any attempted
              assignment in contravention of this provision shall be null and
              void and of no force and effect.
            </p>

            <p>&#8203;</p>

            <p>
              12.9 Entire Agreement. This License including the documents
              incorporated herein by reference constitute the entire agreement
              with respect to the use of the Application licensed hereunder and
              supersedes all prior or contemporaneous understandings regarding
              such subject matter.
            </p>
          </section>

          <footer>
            <p>
              Please note that this return policy is subject to change, and any
              updates will be communicated through our official channels.
            </p>
            <p>
              Thank you for choosing Legends Rally Official. We appreciate your
              understanding and cooperation.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
